<template>
    <el-row>
        <el-col :span="props.data==null?12:24">
            <span style="font-size: 14px;">{{props.text}}</span>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item v-for="(item, index) in list" :key="index"
                    v-bind:to="item.path ? { path: item.path } : null">
                    <span :class="list.length == 1 ? 'd_bold' : ''">{{ item.name }}</span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
        <el-col v-if="props.data==null" :span="12">
            <div style="display: flex;
    align-items: center;
    float: right;
    font-size: 12px;">
                <slot></slot>
            </div>
        </el-col>
    </el-row>
</template>
<script setup>
import { defineProps, ref, toRefs, watch } from 'vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
const props = defineProps({
    text: {
        type: String,
        default: ""
    },
    name: {
        type: String,
        default: ""
    },
    prefixStr: {
        type: String,
        default: ''
    },
    suffixStr: {
        type: String,
        default: ''
    },
    data: {
        type: Array
    }
});
const store = useStore();
const douTable = computed(() => store.state.user.douTable);
const { name, prefixStr, suffixStr, data } = toRefs(props);
const title = ref(name.value);
const list = ref([]);
const GetName = () => {
    let tempList=[];
    if (name.value != null) {
        let tempObj = douTable.value[name.value];
        if (tempObj) {
            title.value = tempObj.name;
        }
    }
    title.value = prefixStr.value + title.value + suffixStr.value;
    if (data.value == null || data.value.length == 0) {
        var obj = { name: "主页", path: "/" };
        tempList.push(obj);
        if (title.value != "") {
            tempList.push({ name: title.value });
        }
    }else{
        tempList = data.value;
    }
    list.value=tempList;
}
GetName();
// 使用watch来观察name的变化  
watch(() => [props.name, props.data], () => {
    GetName();
})
</script>
<style scoped>
.d_bold {
    font-weight: bold;
}
</style>