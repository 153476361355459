<template>
    <span class="dou-badge">{{ name }}</span>
    <el-timeline>
        <el-timeline-item v-for="(line, index) in formData" :key="index" :type="line.type" @click="showInfo(line.id)"
            :color="line.color" :size="line.size" :hollow="line.hollow" :hide-timestamp="line.hideTimestamp"
            :timestamp="line.timestamp" placement="top">
            {{ line.content }}
        </el-timeline-item>
    </el-timeline>
    <el-dialog @closed="hideInfo" v-model="showInfoComponent" :title="entityObj.name + '详情'" width="90%">
        <LayoutInfo :entityName="entityName" :id="infoId" canEdit="false" canDelete="false"></LayoutInfo>
    </el-dialog>
</template>
<script>
import LayoutInfo from '@/components/Content/Layout/LayoutInfo.vue';
import { douTable } from '../../config.js';
export default {
    props: {
        name: {
            type: String
        },
        entityName: {
            type: String
        },
        formData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            infoId: null,
            showInfoComponent:false,
            entityObj:null
        }
    },
    created() {
        var entity = douTable[this.entityName];
        this.entityObj = entity;
    },
    mounted() {

    },
    components: {
        LayoutInfo
    },
    methods: {
        showInfo(id) {
            if (id != null) {
                this.infoId = id;
                this.showInfoComponent=true;
            }
        },
        hideInfo() {
            this.infoId = null;
            this.showInfoComponent=false;
        },
    }
}
</script>
<style>
.el-timeline-item__content {
    text-align: left;
    font-weight: normal;
}

.el-timeline-item__timestamp {
    text-align: left;
}
</style>