<template>
    <el-row v-if="props.showFrom">
        <el-col :col="24">
            <div>
                <el-date-picker v-model="form.date" type="daterange" range-separator="至" start-placeholder="开始时间"
                    end-placeholder="结束时间" :size="size" :editable="false" style="width: 93%;" :clearable="false" />
            </div>
            <div style="margin-top: 8px;">
                <el-select v-model="form.searchText" filterable clearable placeholder="请输入或选择类型" style="width: 50%;">
                    <el-option v-for="item in formBillType" :key="item.value" :label="item.label"
                        :value="item.value" />
                </el-select>
                <el-form-item label="顶级类型" style="display:inline-flex;margin-left: 10px;">
                    <el-switch v-model="form.topLevel" />
                </el-form-item>
            </div>
            <hr />
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24" style="height: 300px;">
            <v-chart class="chart" :option="option" autoresize />
        </el-col>
    </el-row>
</template>
<script setup>
import { ref, defineProps, onMounted, watch } from 'vue';
import VChart from 'vue-echarts';
import * as echarts from 'echarts/core';
import api from '../../../../api/api.js';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import dayjs from 'dayjs';
import { ElMessage } from 'element-plus';
echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
]);
const option = ref({});
const formBillType=ref([]);
const form = ref({
    topLevel: true,
    searchText: ""
});
const props = defineProps(["type", "startDate", "endDate", "searchText", "topLevel","showFrom"]);
onMounted(() => {
    if (props.startDate != null) {
        form.value.date = [new Date(props.startDate), new Date(props.endDate)];
    } else {
        const today = dayjs().startOf('day'); // 当天日期，时间为 0 时
        const tomorrow = dayjs().add(1, 'day').startOf('day');
        let tenmpDate = [new Date(today.format('YYYY-MM-DD')), new Date(new Date(tomorrow.format('YYYY-MM-DD')))];
        form.value.date = tenmpDate;
    }
    draw();
});
watch([() => form.value.date, () => form.value.searchText,() => form.value.topLevel], () => {
    draw();
})
const draw = async () => {
    let start = dayjs(form.value.date[0]).format('YYYY-MM-DD');
    let end = dayjs(form.value.date[1]).format('YYYY-MM-DD');
    let type = 1;
    if (props.type != null) {
        type = parseInt(props.type);
    }
    try {
        const resopnse = await api.GetBillTypePie(type, start, end, form.value.searchText, form.value.topLevel);
        option.value = resopnse.option;
        formBillType.value=resopnse.billType;
    }
    catch (error) {
        ElMessage.error("保存失败！" + error);
    }
}
</script>