<template>
    <el-row>
        <el-col :span="24">
            <GlobalBreadcrumb :name="'保存借贷'"></GlobalBreadcrumb>
        </el-col>
    </el-row>
    <el-row style="margin-top: 10px;">
        <el-col :span="24">
            <el-form :model="form" :rules="rules" label-width="80px" ref="formRef">
                <el-form-item label="方向" prop="BorrowType">
                    <el-select v-model="form.BorrowType" placeholder="请输入或选择">
                        <el-option v-for="item in enumArr['BorrowType']" :key="item.value" :label="item.key"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="名称" prop="Name">
                    <el-input v-model="form.Name"/>
                </el-form-item>
                <el-form-item label="金额" prop="Amount">
                    <el-input v-model="form.Amount" type="number" />
                </el-form-item>
                <el-form-item label="来源方" prop="Source">
                    <el-input v-model="form.Source"/>
                </el-form-item>
                <el-form-item label="来源账户" prop="SourceAccount">
                    <el-select v-model="form.SourceAccount" filterable placeholder="请输入或选择">
                        <el-option v-for="item in formSourceAccountList" :key="item.value" :label="item.label"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="目标方" prop="Target">
                    <el-input v-model="form.Target"/>
                </el-form-item>
                <el-form-item label="目标账户" prop="TargetAccount">
                    <el-select v-model="form.TargetAccount" filterable placeholder="请输入或选择">
                        <el-option v-for="item in formTargetAccountList" :key="item.value" :label="item.label"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="借贷时间" prop="BorrowDate">
                    <el-date-picker :clearable="false" v-model="form.BorrowDate" :placeholder="入账时间" type="date"
                        style="width: 100%" />
                </el-form-item>
                <el-form-item label="备注" prop="memo">
                        <el-input v-model="form.Memo" type="textarea" />
                    </el-form-item>
                <el-form-item label="创建账单">
                    <el-switch v-model="form.createBill" />
                </el-form-item>
            </el-form>
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="24">
            <span class="dialog-footer">
                <el-button type="primary" @click="submitForm">
                    提交
                </el-button>
            </span>
        </el-col>
    </el-row>
</template>
<script>
import { mapState } from 'vuex';
import api from '../../../api/api.js';
import { ElMessage } from 'element-plus';
export default {
    data() {
        return {
            localId: null,//因为props的id不可直接修改
            form: {
                BillTime: new Date(),
                createBill: false
            },//表单
            rules: {},//验证规则
            entityObj: {},//实体
            enumArr: {},//枚举数组
            formSourceAccountList: [],//卡片Select
            formTargetAccountList: [],//卡片Select
            existInfo: {}//查询已存在类型
        }
    },
    async created() {
        let tempId= this.$route.params.id;  
        //新打开时和info控件打开时需要初始化控件
        if (tempId!=null) {
            this.localId = tempId;
        }
        var entity = this.douTable["borrow"];
        this.entityObj = entity;
        this.dynamicUpdateRules();

    },
    async mounted() {
        //初始下拉框
        this.existInfo = await api.GetBorrowExistInfo();
        this.existInfo.card.forEach(m => {
            this.formSourceAccountList.push(m);
            this.formTargetAccountList.push(m);
        });
        //初始化Enum
        await this.initEnum(["BorrowType"]);
         //初始化UI内容
         await this.initData();
    },
    beforeUnmount() {
        console.log('GenericSave|beforeUnmount');
    },
    computed: {
        ...mapState('user', ['douTable'])
    },
    watch: {
        id() {
            this.initData();
        },
        infoId(newVal) {
            console.log("InfoId变更|" + newVal);
            this.localId = newVal;
            this.initData();
        }
    },
    components: {

    },
    methods: {
        //初始化方法
        async initEnum(enums) {
            if (enums.length > 0) {
                //查询所需枚举
                const enumList = await api.GetGenericEnum(enums);
                // 处理登录成功的逻辑
                if (enumList.length > 0) {
                    enumList.forEach(m => {
                        this.enumArr[m.name] = m.data;
                    });
                }
            }
        },
        async initData() {
            console.log("Save初始化|" + this.localId);
            if (this.localId != null && this.localId!="0") {
                var resopnse = await api.GetGenericDataById(this.entityObj.entityName, this.localId);
                if (resopnse != null) {
                    var data = resopnse;
                    this.form = data;
                }
            } else {
                if(this.localId==null){
                    this.form={};
                }
            }
        },
        dynamicUpdateRules() {
            var tempRules = {};
            this.entityObj.attr.forEach(element => {
                var tempRule = {
                    required: true,
                    trigger: "blur"
                };
                switch (element.type) {
                    case "input":
                    case "textarea":
                    case "date":
                    case "number":
                    case "time":
                    default: {
                        if (element.required == true) {
                            tempRule.message = "请输入" + element.display;
                        }
                    } break;
                    case "selectData":
                    case "datetime":
                    case "enum": {
                        if (element.required == true) {
                            tempRule.message = "请选择" + element.display;
                        }
                    } break;
                }
                if (element.required) {
                    tempRules[element.name] = [tempRule];
                }
            });
            this.rules = tempRules;
        },
        async submitForm() {
            console.log("保存表单");
            var self = this;
            this.$refs.formRef.validate(async (valid) => {
                if (valid) {
                    var tempObj = {};
                    Object.keys(self.form).forEach(m => {
                        tempObj[m] = self.form[m];
                    });
                    try {
                        try {
                            await api.BorrowSave(tempObj);
                            window.history.go(-1);
                        } catch (error) {
                            // 处理登录失败的逻辑
                            ElMessage.error("保存失败！" + error);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    console.log('表单验证失败');
                    return false;
                }
            });
        }
    }
}
</script>
<style scoped></style>