<template>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">基础信息</span>
            <el-descriptions class="margin-top" :column="1" border>
                <el-descriptions-item v-if="info.meet!=null">
                    <template #label>
                        <div class="cell-item">
                            已相识
                        </div>
                    </template>
                    <span>{{ info.meet }}</span>
                </el-descriptions-item>
            </el-descriptions>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">自定义特性</span>
            <div class="d-tab d-center">
                <el-button type="primary" @click="addCustom">添加自定义特性</el-button>
            </div>
            <el-table :data="list" stripe style="width: 100%" @row-click="golistInfo">
                <el-table-column width="100" prop="name" label="特性名" />
                <el-table-column prop="content" label="内容" />
            </el-table>
        </el-col>
    </el-row>
    <el-dialog v-model="showDialog" title="添加自定义特性" width="90%">
        <el-form label-width="68px" ref="formRef" :model="form">
            <el-form-item label="特性名">
                <el-input v-model="form.Name" placeholder="请输入调整后的值"></el-input>
            </el-form-item>
            <el-form-item label="内容">
                <el-input v-model="form.Content" type="textarea"></el-input>
            </el-form-item>
        </el-form>
        <span class="dialog-footer">
            <el-button type="primary" @click="showDialogSubmit">
                提交
            </el-button>
        </span>
    </el-dialog>
</template>

<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue';
const props = defineProps(['entityName', "id"]);
import api from '../../../api/api.js';
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
const form = ref({});
const info=ref({});
const showDialog = ref(false);
const list = ref([]);
const router = useRouter();
const store = useStore();
const emit = defineEmits(['updateEvent']);
onMounted(() => {
    initPage();
})
const initPage = async () => {
    let friendExt = await api.GetFriendExt(props.id);
    list.value = friendExt.extras;
    info.value=friendExt.info;
}
const addCustom = () => {
    showDialog.value = true;
}
const showDialogSubmit = async () => {
    let tempObj = {};
    tempObj.Name = form.value.Name;
    tempObj.Content = form.value.Content;
    tempObj.FriendId = props.id;
    try {
        await api.SaveGenericData("FriendExtra", tempObj);
        ElMessage({
            message: "操作成功",
            type: 'success'
        })
        showDialog.value = false;
        form.value = {};
        initPage();
    } catch (e) {
        console.log(e);
        ElMessage({
            message: e.response.data,
            type: 'error'
        })
    }
}
const golistInfo = (e) => {
    let tempId = e.id;
    router.push('/pi/friendextra/' + tempId);
    emit("updateEvent", true);
    //切换标签页
    store.commit('generic/SET_CHILDUPDATETAB', true);
}
</script>