<template>
    <el-row>
        <el-col :span="24">
            <GlobalBreadcrumb :name="entityName" suffixStr="列表"></GlobalBreadcrumb>
        </el-col>
    </el-row>
    <DouList :entityName="entityObj.entityName" :listProp="listProp" :infoHref="infoHref">
        <template #soltAdd>
            <el-button @click="goPath" style="margin:0">
                <el-icon>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path>
                    </svg>
                </el-icon>
            </el-button>
        </template>
    </DouList>
</template>
<script>
import { mapState } from 'vuex';
import DouList from './../../Content/Dou/DouList.vue';
export default {
    props: {
        entityName: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            entityObj: {},//表对象
            infoHref: "",
            listProp: {}
        }
    },
    created() {
        this.initEntity();
    },
    computed: {
        ...mapState('user', ['douTable'])
    },
    watch: {
        entityName() {
            this.initEntity();
        }
    },
    components: {
        DouList
    },
    methods: {
        goPath() {
            var layoutStr = this.$router.currentRoute._value.path.split('/')[1];
            this.entityObj = this.douTable[this.entityName];
            let path = "";
            switch (this.entityName) {
                case "borrow": {
                    path = "/Bill/BorrowSave/0";
                } break;
                default: {
                    if (layoutStr == "pl") {
                        path = "/ps/" + this.entityName + "/0";
                    } else {
                        path = "/" + layoutStr + "/ps/" + this.entityName + "/0";
                    }
                } break;
            }
            this.$router.push({ path: path });
        },
        initEntity() {
            this.entityObj = this.douTable[this.entityName];
            var layoutStr = this.$router.currentRoute._value.path.split('/')[1];
            var path = "/pi/" + this.entityName;
            if (layoutStr != "pl") {
                path = "/" + layoutStr + "/pi/" + this.entityName;
            } 
            this.infoHref = path;
            this.listProp.name = "m.name";
            switch (this.entityName) {
                case "note": {
                    this.listProp.memo1 = "m.content.substring(0, 50) + '...'";
                } break;
            }
        }
    }
}
</script>
<style>
.el-form--inline .el-form-item {
    margin-right: 10px;
}
</style>