<template>
  <el-row>
    <el-col :span="24">
      <GlobalBreadcrumb :name="'保存笔记'"></GlobalBreadcrumb>
    </el-col>
  </el-row>
  <el-row style="margin-top: 10px;">
    <el-col :span="24">
      <el-form :model="form" :rules="rules" ref="formRef">
        <el-form-item label="标题">
          <el-input v-model="form.Name" />
        </el-form-item>
        <el-form-item label="类型" prop="Type">
          <el-select v-model="form.Type" filterable allow-create placeholder="请输入或选择">
            <el-option v-for="item in formTypeList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
  <span class="dou-badge">段落编辑</span>
  <hr />
  <el-row style="margin-top: 10px;" class="sticky-div">
    <el-col :span="24">
      <div style="border: 1 solid black;margin: 5px 10px 5px 0;">
        <div style="display: flex;justify-items: center;">
          <el-select v-if="selectedIndex === null || groups[selectedIndex].IsSpecial == false" style="max-width: 150px;"
            v-model="selectedGroupType" :disabled="selectedIndex === null" placeholder="请选择类型"
            @change="updateGroupType">
            <el-option v-for="item in paragraphTypes.normal" :key="item" :label="item" :value="item" />
          </el-select>
          <el-select v-else style="max-width: 150px;" v-model="selectedGroupType" disabled placeholder="请选择类型">
            <el-option v-for="item in paragraphTypes.special" :key="item" :label="item" :value="item" />
          </el-select>
          <div style="display: flex; justify-items: center;" v-if="selectedIndex !== null">
            <span style="margin:5px;display: flex;justify-content: center;" @click="insertBefore(selectedIndex)">
              <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path
                  d="M20 13C20.5523 13 21 13.4477 21 14V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V14C3 13.4477 3.44772 13 4 13H20ZM19 15H5V19H19V15ZM12 1C14.7614 1 17 3.23858 17 6C17 8.76142 14.7614 11 12 11C9.23858 11 7 8.76142 7 6C7 3.23858 9.23858 1 12 1ZM13 3H11V4.999L9 5V7L11 6.999V9H13V6.999L15 7V5L13 4.999V3Z">
                </path>
              </svg>
            </span>
            <span style="display: flex;justify-content: center;align-items: center;">|</span>
            <span style="margin:5px;display: flex;justify-content: center;" @click="insertAfter(selectedIndex)">
              <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path
                  d="M12 13C14.7614 13 17 15.2386 17 18C17 20.7614 14.7614 23 12 23C9.23858 23 7 20.7614 7 18C7 15.2386 9.23858 13 12 13ZM13 15H11V16.999L9 17V19L11 18.999V21H13V18.999L15 19V17L13 16.999V15ZM20 3C20.5523 3 21 3.44772 21 4V10C21 10.5523 20.5523 11 20 11H4C3.44772 11 3 10.5523 3 10V4C3 3.44772 3.44772 3 4 3H20ZM5 5V9H19V5H5Z">
                </path>
              </svg>
            </span>
            <span style="display: flex;justify-content: center;align-items: center;">|</span>
            <span style="margin:5px;display: flex;justify-content: center;" @click="formatContent(selectedIndex)">
              <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M3 4H21V6H3V4ZM3 19H21V21H3V19ZM11 14H21V16H11V14ZM11 9H21V11H11V9ZM7 12.5L3 16V9L7 12.5Z">
                </path>
              </svg>
            </span>
            <span style="display: flex;justify-content: center;align-items: center;">|</span>
            <span style="margin:5px;display: flex;justify-content: center;" @click="removeGroup(selectedIndex)"
              v-if="groups.length != 1">
              <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path
                  d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z">
                </path>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
  <el-row style="margin-top: 10px;">
    <el-col :span="24">
      <div v-for="(group, index) in groups" :key="group.id" @click="selectGroup(index)">
        <div v-if="group.Type != '截图'" contenteditable="true" class="d-editable-div"
          @input="updateContent($event, index)" @keydown="handleKeydown($event, index)" v-text="group.Content"></div>
        <div v-if="group.Type == '截图'" :class="{ 'd-editable-div': true, 'd_selected': selectedIndex === index }">
          <img :src="group.Content" style="max-width:100%" />
        </div>
      </div>
    </el-col>
  </el-row>
  <el-row style="margin-top: 5px;">
    <el-col :span="24">
      <span class="dialog-footer">
        <el-button type="primary" @click="submitForm">
          提交
        </el-button>
      </span>
    </el-col>
  </el-row>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount, computed, defineProps, nextTick, watch } from 'vue';
import { useRouter,useRoute } from 'vue-router';
// import { mapMutations, mapState } from 'vuex';
import api from '../../../api/api.js';
import { ElMessage } from 'element-plus';
// import dayjs from 'dayjs';

// UUID 生成函数
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
const router = useRouter();
const route = useRoute();
const existInfo = ref({})//查询已存在类型
const formTypeList = ref([]);
const form = ref({});
const props = defineProps(["id"]);
const tempParagraphTypes = {
  normal: ["正文", "一级段落", "二级段落", "三级段落", "C#", "JavaScript", "HTML", "CSS", "SQL", "XML", "JSON", "Java"], // 普通段落
  special: ["截图", "文件"] // 特殊段落
};
const paragraphTypes = ref(tempParagraphTypes);
onMounted(async () => {
  existInfo.value = await api.GetNoteExistInfo();
  existInfo.value.type.forEach(m => {
    formTypeList.value.push(m);
  });
  if (props.id != null && props.id != "0") {
    const response = await api.GetNoteById(props.id);
    form.value.Name = response.name;
    form.value.Type = response.type;
    await Promise.all(
      response.paragraphs.map(async m => {
        if (m.type === '截图' && m.image == null) {
          try {
            m.content = await api.getPImage(m.id);
          }
          catch {
            m.content = "";
          }
        }
        if (tempParagraphTypes.special.indexOf(m.type) > -1) {
          m.IsSpecial = true;
        } else {
          m.IsSpecial = false;
        }
      })
    );
    var tempParagraphs = response.paragraphs.map(p => ({
      Id: p.id,
      Content: p.content,
      Type: p.type,
      PrevId: p.prevId,
      NextId: p.nextId,
      IsSpecial: p.IsSpecial
    }));
    groups.value = sortGroups(tempParagraphs);
  }
  window.addEventListener('paste', handlePaste);
});

onBeforeUnmount(() => {
  window.removeEventListener('paste', handlePaste); // 组件卸载时移除监听器
});

// 初始化组
const groups = ref([
  {
    Id: generateUUID(), // 使用自定义UUID生成函数
    Content: '', // 初始内容为空
    Type: "正文",
    PrevId: null,
    NextId: null,
    IsSpecial: false
  }
])

// 当前选择的组索引
const selectedIndex = ref(null)

// 计算当前选中组的类型
const selectedGroupType = computed({
  get() {
    if (selectedIndex.value !== null) {
      return groups.value[selectedIndex.value].Type
    }
    return '未选择'
  },
  set(value) {
    if (selectedIndex.value !== null) {
      groups.value[selectedIndex.value].Type = value
    }
  }
})

// 更新组的类型
const updateGroupType = () => {
  if (selectedIndex.value !== null) {
    groups.value[selectedIndex.value].type = selectedGroupType.value
  }
}

// 选择组
const selectGroup = (index) => {
  selectedIndex.value = index
}

// 在指定位置前插入新组
const insertBefore = async (index) => {
  const newGroup = {
    Id: generateUUID(),
    Content: '',
    Type: '正文',
    IsSpecial: false,
    PrevId: index > 0 ? groups.value[index - 1].Id : null,
    NextId: groups.value[index].Id
  }

  // 更新当前组的 PrevId 和前一个组的 NextId
  if (index > 0) {
    groups.value[index - 1].NextId = newGroup.Id
  }
  groups.value[index].PrevId = newGroup.Id
  groups.value.splice(index, 0, newGroup)
  await nextTick();
  setFocus(index);
}

// 在指定位置后插入新组
const insertAfter = async (index) => {
  var newGroup = {
    Id: generateUUID(),
    Content: '',
    Type: '正文',
    IsSpecial: false,
    PrevId: groups.value[index].Id,
    NextId: index < groups.value.length - 1 ? groups.value[index + 1].Id : null
  }
  // 更新当前组的 NextId 和下一个组的 PrevId
  if (index < groups.value.length - 1) {
    groups.value[index + 1].PrevId = newGroup.Id
  }
  groups.value[index].NextId = newGroup.Id
  groups.value.splice(index + 1, 0, newGroup)
  await nextTick();
  setFocus(index + 1);
}

// 删除指定组
const removeGroup = (index) => {
  if (groups.value.length > 1) {
    const PrevId = groups.value[index].PrevId
    const NextId = groups.value[index].NextId

    // 如果当前组有前一个组，更新前一个组的 NextId
    if (PrevId) {
      const prevGroup = groups.value.find(group => group.Id === PrevId)
      prevGroup.NextId = NextId
    }

    // 如果当前组有后一个组，更新后一个组的 PrevId
    if (NextId) {
      const nextGroup = groups.value.find(group => group.Id === NextId)
      nextGroup.PrevId = PrevId
    }

    // 删除当前组
    groups.value.splice(index, 1)

    // 如果删除的是当前选中的组，取消选择
    if (selectedIndex.value === index) {
      selectedIndex.value = null
    }
    if (index > 0) {
      setFocus(index - 1);
    } else {
      setFocus(index);
    }
  }
}

// 格式化内容，基于选择的格式 (JSON 或 XML)
const formatContent = (index) => {
  let type = groups.value[index].Type;
  switch (type) {
    case 'JavaScript':
    case 'C#':
    case 'JSON': {
      formatJSONContent(index)
    } break;
    case 'XML': {
      formatXMLContent(index)
    } break;
  }
}

// JSON 格式化逻辑
const formatJSONContent = (index) => {
  const indentSize = 3
  let indentLevel = 0
  const content = groups.value[index].Content

  const formattedContent = content
    .split('\n')
    .map(line => {
      const trimmedLine = line.trim()

      if (trimmedLine.startsWith('}')) {
        indentLevel = Math.max(indentLevel - 1, 0)
      }

      const indentedLine = ' '.repeat(indentLevel * indentSize) + trimmedLine

      if (trimmedLine.endsWith('{')) {
        indentLevel++
      }

      return indentedLine
    })
    .join('\n')

  groups.value[index].Content = formattedContent
}

// XML 格式化逻辑
const formatXMLContent = (index) => {
  const indentSize = 3
  let indentLevel = 0
  const content = groups.value[index].Content

  const formattedContent = content
    .split('\n')
    .map(line => {
      const trimmedLine = line.trim()

      if (trimmedLine.startsWith('</')) {
        indentLevel = Math.max(indentLevel - 1, 0)
      }

      const indentedLine = ' '.repeat(indentLevel * indentSize) + trimmedLine

      if (trimmedLine.startsWith('<') &&
        !trimmedLine.startsWith('</') &&
        !trimmedLine.endsWith('/>') &&
        !trimmedLine.includes('</')) {
        indentLevel++
      }

      return indentedLine
    })
    .join('\n')

  groups.value[index].Content = formattedContent
}

const sortGroups = (groupList) => {
  const idMap = new Map(groupList.map(g => [g.Id, g]));
  let startGroup = groupList.find(g => g.PrevId === null);

  const sorted = [];
  while (startGroup) {
    sorted.push(startGroup);
    startGroup = idMap.get(startGroup.NextId);
  }
  return sorted;
};

const pasteImg = async (e, index) => {
  for (let i = 0; i < e.clipboardData.items.length; i++) {
    const item = e.clipboardData.items[i];
    console.log(item.type);
    if (item.type.startsWith('image/')) {
      e.preventDefault();
      const file = item.getAsFile();

      try {
        // 使用 FileReader 获取本地 base64
        const reader = new FileReader();
        reader.onloadend = async () => {
          //const base64Str = reader.result.substr(reader.result.indexOf(',') + 1);
          const base64Str = reader.result;
          // 压缩图片
          const formData = new FormData();
          formData.append('file', file);

          // 调用封装的上传方法，获取临时 URL
          const tempUrl = await api.UploadPTemp(formData);

          // 插入或替换图片段落
          if (!groups.value[index].IsSpecial) {
            // 如果文本框内容为空，替换为图片段落
            if (groups.value[index].Content.trim() === '') {
              groups.value.splice(index, 1, {
                Id: groups.value[index].Id,
                Content: base64Str, // 使用本地 base64 展示
                Type: '截图',
                IsSpecial: true,
                PrevId: groups.value[index].PrevId,
                NextId: groups.value[index].NextId,
                Url: tempUrl // 保存临时 URL 用于后续操作
              });
            } else {
              // 如果文本框内容不为空，插入新图片段落
              const newGroup = {
                Id: generateUUID(),
                Content: base64Str,
                Type: '截图',
                IsSpecial: true,
                PrevId: groups.value[index].Id,
                NextId: index < groups.value.length - 1 ? groups.value[index + 1].Id : null,
                Url: tempUrl
              };

              // 更新前后段落关系
              if (index < groups.value.length - 1) {
                groups.value[index + 1].PrevId = newGroup.Id;
              }
              groups.value[index].NextId = newGroup.Id;

              groups.value.splice(index + 1, 0, newGroup);
            }
          } else {
            // 更新现有截图段落
            groups.value[index].Content = base64Str;
            groups.value[index].Url = tempUrl;
          }
        };

        reader.readAsDataURL(file); // 读取本地图片文件为 base64
      } catch (error) {
        ElMessage.error("图片粘贴保存失败！" + error);
      }
      break;
    }
  }
};

const handleKeydown = async (event, index) => {
  var currGroup = groups.value[index];
  var currHTML = event.target.innerText;
  if (event.key === 'Enter') {
    event.preventDefault();  // 防止默认的换行行为
    //插入新段落
    const newGroup = {
      Id: generateUUID(),
      Content: '',
      Type: '正文',
      IsSpecial: false,
      PrevId: groups.value[index].Id,
      NextId: index < groups.value.length - 1 ? groups.value[index + 1].Id : null,
    };

    // 更新当前段落的 NextId 和下一个段落的 PrevId
    if (index < groups.value.length - 1) {
      groups.value[index + 1].PrevId = newGroup.Id;
    }
    groups.value[index].NextId = newGroup.Id;
    groups.value.splice(index + 1, 0, newGroup);
    // 等待 DOM 更新完成后聚焦到新段落
    await nextTick();

    // 获取新插入的段落元素，并将焦点设置到它
    setFocus(index + 1);
  }
  else if (event.key === 'Backspace') {
    if (currHTML == "") {
      if (groups.value.length > 1) {
        event.preventDefault();
        const PrevId = currGroup.PrevId
        const NextId = currGroup.NextId

        // 如果当前组有前一个组，更新前一个组的 NextId
        if (PrevId) {
          const prevGroup = groups.value.find(group => group.Id === PrevId)
          prevGroup.NextId = NextId
        }

        // 如果当前组有后一个组，更新后一个组的 PrevId
        if (NextId) {
          const nextGroup = groups.value.find(group => group.Id === NextId)
          nextGroup.PrevId = PrevId
        }

        // 删除当前组
        groups.value.splice(index, 1)

        // 如果删除的是当前选中的组，取消选择
        if (selectedIndex.value === index) {
          selectedIndex.value = null
        }
        if (index != 0) {
          // 获取下一个段落元素，并将焦点设置到它
          setFocus(index - 1, true);
        }
      }
    }
  }
  currGroup.Content = currHTML;
}

// 更新内容的方法
const updateContent = (event, index) => {
  // 直接从事件对象中获取当前文本内容
  groups.value[index].Content = event.target.innerText; // 更新 groups 数组中的内容
};
// 监听 groups 的变化，更新对应 div 的内容
watch(groups, (newGroups) => {
  // 使用 newGroups 来避免 ESLint 警告
  newGroups.forEach((group, index) => {
    // 这里可以添加其他逻辑，比如检查内容是否变化等
    console.log(`Group ${index} updated:`, group.content);
  });
});
//处理焦点的方法
const setFocus = (index, isLast) => {
  // 过滤出非截图类型的段落的 contenteditable 元素
  const editableElements = document.querySelectorAll('[contenteditable]');
  // 找出当前段落之前的非截图段落的数量
  const nonImageIndex = groups.value.slice(0, index).filter(group => group.Type !== '截图').length;
  // 设置焦点到对应的非截图段落
  const newParagraphElement = editableElements[nonImageIndex];
  if (newParagraphElement != null) {
    newParagraphElement.focus();
  }
  //设置光标尾部
  if (isLast) {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(newParagraphElement);
    range.collapse(false);  // 光标移到段落末尾
    selection.removeAllRanges();
    selection.addRange(range);
  }
  //设置选中
  selectedIndex.value = index;
}

//处理全局粘贴
const handlePaste = (event) => {
  if (selectedIndex.value !== null) {
    pasteImg(event, selectedIndex.value); // 将粘贴事件处理交给pasteImg
  }
};

const submitForm = async () => {
  var b = true;
  if (b) {
    let tempObj = {};
    tempObj.Name = form.value.Name;
    tempObj.Type = form.value.Type;
    let tempGroups = [];
    groups.value.forEach(m => {
      let tempP = {};
      tempP.Id = m.Id;
      tempP.Type = m.Type;
      if (tempP.Type == "截图") {
        if (m.Url != null) {
          tempP.Content = m.Url;
        } else {
          tempP.Content = "";
        }
      } else {
        tempP.Content = m.Content;
      }
      tempP.PrevId = m.PrevId;
      tempP.NextId = m.NextId;
      tempGroups.push(tempP);
    });
    tempObj.Paragraphs = tempGroups;
    if (props.id != null && props.id != '0') {
      tempObj.Id = props.id;
    }else{
      if(route.query.fid!=null && route.query.fid!=""){
        tempObj.FolderId=route.query.fid;
      }
    }
    try {
      await api.SaveNote(tempObj);
      router.back();
    } catch (error) {
      // 处理登录失败的逻辑
      ElMessage.error("保存失败！" + error.response.data);
    }
  }
}
</script>
<style scoped>
.sticky-div {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  /* 确保它在其他元素之上 */
}

.d_screenscan {
  display: flex;
  justify-content: center;
  width: 100%;
}

.d-editable-div {
  padding: 6px 10px;
  /* 内边距 */
  outline: none;
  /* 去除默认的黄框 */
  transition: border-color 0.3s;
  /* 添加过渡效果 */
  font-size: 14px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  margin: 1px 0;
}

.d-editable-div:focus,
.d_selected {
  border-color: #409eff;
  /* 获取焦点时的边框颜色 */
  box-shadow: 0 0 5px #409eff;
  /* 获取焦点时的阴影效果 */
}
</style>