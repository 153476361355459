<template>
    <el-row>
        <el-col :span="24">
            <GlobalBreadcrumb :name="'笔记'" suffixStr="列表"></GlobalBreadcrumb>
        </el-col>
    </el-row>
    <el-row style="margin-top: 8px;">
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item>
                    <el-input v-model="searchText" />
                </el-form-item>
                <el-form-item>
                    <el-button @click="goPath">
                        <el-icon>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path>
                            </svg>
                        </el-icon>
                    </el-button>
                </el-form-item>
            </el-form>
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="24" style="margin-top: 5px;">
            <GlobalBreadcrumb :text="'当前路径:'" :data="folderRouter"></GlobalBreadcrumb>
        </el-col>
    </el-row>
    <el-row style="padding-top: 10px;" class="sticky-div">
        <el-col :span="24">
            <div style="border: 1 solid black;">
                <div style="display: flex;justify-items: center;">
                    <div style="display: flex; justify-items: center;">
                        <span style="margin:5px 5px 5px 0;display: flex;justify-content: center;" @click="addFolder">
                            <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M12.4142 5H21C21.5523 5 22 5.44772 22 6V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H10.4142L12.4142 5ZM4 5V19H20V7H11.5858L9.58579 5H4ZM11 12V9H13V12H16V14H13V17H11V14H8V12H11Z">
                                </path>
                            </svg>
                        </span>
                        <span v-if="props.id != '0'"
                            style="display: flex;justify-content: center;align-items: center;">|</span>
                        <span v-if="props.id != '0'" style="margin:5px;display: flex;justify-content: center;"
                            @click="updateFolder">
                            <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M12.4142 5H21C21.5523 5 22 5.44772 22 6V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H10.4142L12.4142 5ZM4 5V19H20V7H11.5858L9.58579 5H4ZM8.59114 13.8089C8.52934 13.5486 8.49663 13.277 8.49663 12.9978C8.49663 12.7186 8.52933 12.4471 8.59112 12.1868L7.59998 11.6146L8.59949 9.88335L9.59133 10.456C9.98424 10.0843 10.4633 9.80275 10.9954 9.64438V8.5H12.9944V9.64438C13.5265 9.80274 14.0056 10.0843 14.3985 10.4559L15.3904 9.88325L16.39 11.6145L15.3987 12.1867C15.4605 12.447 15.4932 12.7186 15.4932 12.9978C15.4932 13.277 15.4605 13.5485 15.3987 13.8088L16.39 14.3811L15.3905 16.1123L14.3986 15.5396C14.0057 15.9113 13.5266 16.1928 12.9945 16.3512V17.4956H10.9955V16.3513C10.4634 16.1929 9.98434 15.9114 9.59141 15.5397L8.59954 16.1124L7.59998 14.3812L8.59114 13.8089ZM11.9949 14.4971C12.8229 14.4971 13.4942 13.8258 13.4942 12.9978C13.4942 12.1698 12.8229 11.4985 11.9949 11.4985C11.1669 11.4985 10.4957 12.1698 10.4957 12.9978C10.4957 13.8258 11.1669 14.4971 11.9949 14.4971Z">
                                </path>
                            </svg>
                        </span>
                        <span v-if="props.id != '0'"
                            style="display: flex;justify-content: center;align-items: center;">|</span>
                        <span v-if="props.id != '0'" style="margin:5px;display: flex;justify-content: center;"
                            @click="moveFolder">
                            <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M22 13H20V7H11.5858L9.58579 5H4V19H13V21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H10.4142L12.4142 5H21C21.5523 5 22 5.44772 22 6V13ZM18 17V13.5L23 18L18 22.5V19H15V17H18Z">
                                </path>
                            </svg>
                        </span>
                        <span style="display: flex;justify-content: center;align-items: center;">|</span>
                        <span style="margin:5px;display: flex;justify-content: center;" @click="delteFolder">
                            <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z">
                                </path>
                            </svg>
                        </span>
                        <span style="display: flex;justify-content: center;align-items: center;">|</span>
                        <span style="margin:5px;display: flex;justify-content: center;" @click="addNote">
                            <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M4 1V4H1V6H4V9H6V6H9V4H6V1H4ZM3 20.0066V11H5V19H13V14C13 13.45 13.45 13 14 13L19 12.999V5H11V3H20.0066C20.5552 3 21 3.45576 21 4.00247V15L15 20.996L4.00221 21C3.4487 21 3 20.5551 3 20.0066ZM18.171 14.999L15 15V18.169L18.171 14.999Z">
                                </path>
                            </svg>
                        </span>
                        <span style="display: flex;justify-content: center;align-items: center;">|</span>
                        <span style="margin:5px;display: flex;justify-content: center;">
                            <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M13.0607 8.11097L14.4749 9.52518C17.2086 12.2589 17.2086 16.691 14.4749 19.4247L14.1214 19.7782C11.3877 22.5119 6.95555 22.5119 4.22188 19.7782C1.48821 17.0446 1.48821 12.6124 4.22188 9.87874L5.6361 11.293C3.68348 13.2456 3.68348 16.4114 5.6361 18.364C7.58872 20.3166 10.7545 20.3166 12.7072 18.364L13.0607 18.0105C15.0133 16.0578 15.0133 12.892 13.0607 10.9394L11.6465 9.52518L13.0607 8.11097ZM19.7782 14.1214L18.364 12.7072C20.3166 10.7545 20.3166 7.58872 18.364 5.6361C16.4114 3.68348 13.2456 3.68348 11.293 5.6361L10.9394 5.98965C8.98678 7.94227 8.98678 11.1081 10.9394 13.0607L12.3536 14.4749L10.9394 15.8891L9.52518 14.4749C6.79151 11.7413 6.79151 7.30911 9.52518 4.57544L9.87874 4.22188C12.6124 1.48821 17.0446 1.48821 19.7782 4.22188C22.5119 6.95555 22.5119 11.3877 19.7782 14.1214Z">
                                </path>
                            </svg>
                        </span>
                        <span style="display: flex;justify-content: center;align-items: center;">|</span>
                        <span style="margin:5px;display: flex;justify-content: center;">
                            <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M13 14H11C7.54202 14 4.53953 15.9502 3.03239 18.8107C3.01093 18.5433 3 18.2729 3 18C3 12.4772 7.47715 8 13 8V2.5L23.5 11L13 19.5V14ZM11 12H15V15.3078L20.3214 11L15 6.69224V10H13C10.5795 10 8.41011 11.0749 6.94312 12.7735C8.20873 12.2714 9.58041 12 11 12Z">
                                </path>
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </el-col>
    </el-row>
    <div v-for="(item, index) in folders" :key="index">
        <el-row style="margin-top:5px" @click="goFolderHref(item.id, $event)">
            <el-col :span="24">
                <div style="display: flex;justify-items: center;">
                    <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path
                            d="M4 5V19H20V7H11.5858L9.58579 5H4ZM12.4142 5H21C21.5523 5 22 5.44772 22 6V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H10.4142L12.4142 5Z">
                        </path>
                    </svg>
                    <span style="margin-left: 3px;">{{ item.name }}</span>
                </div>
                <div style="font-size: 12px;color: gray;" v-if="item.description">{{ item.description }}</div>
            </el-col>
        </el-row>
        <hr />
    </div>
    <div v-for="(item, index) in list" :key="index">
        <el-row style="margin-top:5px" @click="goInfoHref(item.id, $event)">
            <el-col :span="24">
                <div style="display: flex;justify-items: center;">
                    <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path
                            d="M21 8V20.9932C21 21.5501 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.4487 2 4.00221 2H14.9968L21 8ZM19 9H14V4H5V20H19V9ZM8 7H11V9H8V7ZM8 11H16V13H8V11ZM8 15H16V17H8V15Z">
                        </path>
                    </svg>
                    <span style="margin-left: 3px;">{{ item.name }}</span>
                </div>
                <div style="font-size: 12px;color: gray;">
                    <span v-if="item.modifyOn">修改于:{{ item.modifyOn }}</span>
                </div>
            </el-col>
        </el-row>
        <hr />
    </div>
    <div v-if="list.length > 0">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            v-model:current-page="pageNo" v-model:page-size="pageSize" layout="sizes, prev, pager, next"
            :small="'small'" :total="totalCount" :page-size="pageSize" :pager-count="5"
            :page-sizes="[10, 20, 30, 50]" />
    </div>
    <div v-if="list.length == 0 && folders.length == 0" style="display:flex;justify-content: center;">
        <span style="color: gray;font-size: 14px;">暂无内容</span>
    </div>
    <el-dialog v-model="showSaveDialog" :title="(saveType == 1 ? '新增' : '修改') + '文件夹'" width="90%">
        <el-form :model="form" label-width="68px" ref="formRef">
            <el-form-item label="名称" prop="Name">
                <el-input v-model="form.Name" type="input" />
            </el-form-item>
            <el-form label-width="68px">
                <el-form-item label="描述">
                    <el-input rows="3" v-model="form.Description" type="textarea" />
                </el-form-item>
            </el-form>
        </el-form>
        <span class="dialog-footer">
            <el-button type="primary" @click="saveFolder">
                提交
            </el-button>
        </span>
    </el-dialog>
    <el-dialog v-model="showMoveDialog" title="移动文件夹" width="90%">
        <el-tree ref="treeRef" class="filter-tree" :data="folderList" :props="defaultProps" default-expand-all
            :filter-node-method="filterNode" :expand-on-click-node="false" @node-click="choosedFolder" />
        <span class="dialog-footer">
            <el-button type="primary" @click="moveFolderSubmit">提交</el-button>
        </span>
    </el-dialog>
</template>
<script setup>
import { ref, onMounted, defineProps, watch } from 'vue';
import api from '../../../api/api.js';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { ElMessageBox } from 'element-plus';
import dayjs from 'dayjs';
// import { mapState } from 'vuex';
const searchText = ref("");
const list = ref([]);
const folders = ref([]);
const router = useRouter();
const props = defineProps(["id"]);
const folderId = ref("");
const info = ref({});
const folderRouter = ref([]);
onMounted(() => {
    initPage();
});
const initPage = async () => {
    if (props.id != '0') {
        folderId.value = props.id;
    } else {
        folderId.value = "";
    }
    var apiResult = await api.GetNoteList(1, 50, null, null, folderId.value);
    let tempList = [];
    apiResult.list.Data.forEach(m => {
        m.createdOn = dayjs(m.createdOn + "Z").format('YYYY-MM-DD HH:mm');
        if (m.modifyOn != null) {
            m.modifyOn = dayjs(m.modifyOn + "Z").format('YYYY-MM-DD HH:mm');
        }
        tempList.push(m);
    });
    list.value = tempList;
    folders.value = apiResult.folders;
    if (apiResult.info != null) {
        info.value = apiResult.info;
    }

    var tempRouter = [];
    tempRouter.push({ name: "目录", path: "/NoteList/0" });
    apiResult.level.forEach(element => {
        tempRouter.push({ name: element.name, path: "/NoteList/" + element.id });
    });
    folderRouter.value = tempRouter;
}
// 监听 id 的变化
watch(() => props.id, () => {
    initPage(); // 当 id 改变时重新加载数据
});
const goPath = () => {
    var path = "/NoteSave/0";
    router.push({ path: path });
};
const goInfoHref = (id) => {
    var path = "/NoteInfo/" + id;
    router.push({ path: path });
}
const goFolderHref = (id) => {
    var path = "/NoteList/" + id;
    router.push({ path: path });
}
const showSaveDialog = ref(false);
const saveType = ref(-1);//1新增2修改
const form = ref({});
const addFolder = () => {
    showSaveDialog.value = true;
    form.value = {};
    saveType.value = 1;
}
const updateFolder = () => {
    showSaveDialog.value = true;
    form.value.Name = info.value.name;
    form.value.Description = info.value.description;
    saveType.value = 2;
}
const saveFolder = async () => {
    let tempObj = form.value;
    if (saveType.value == 1) {
        if (folderId.value != "") {
            tempObj.NoteFolderId = folderId;
        }
    } else if (saveType.value == 2) {
        if (folderId.value != "0") {
            tempObj.Id = folderId;
        }
    }
    try {
        await api.SaveNoteFolder(tempObj);
        showSaveDialog.value = false;
        initPage();
    } catch (error) {
        // 处理登录失败的逻辑
        ElMessage.error("保存失败！" + error.response.data);
    }
}
const showMoveDialog = ref(false);
const folderList = ref([]);
//Tree
const defaultProps = {
    children: 'children',
    label: 'label',
    class: 'class'
};
const moveFolder = async () => {
    showMoveDialog.value = true;
    folderList.value = await api.GetNoteFolderTree();
}
const filterNode = (value, data) => {
    if (!value) return true;
    return data.label.includes(value);
};
const choosedFolderInfo = ref("");
const choosedFolder = (obj) => {
    choosedFolderInfo.value = obj.id;
}
const moveFolderSubmit = async () => {
    let tempObj = {};
    tempObj.Id = folderId.value;
    tempObj.FolderId = choosedFolderInfo.value;
    if (tempObj.Id != tempObj.FolderId) {
        try {
            await api.MoveNoteFolder(tempObj);
            showMoveDialog.value = false;
            initPage();
            ElMessage({
                message: '移动成功！',
                type: 'success',
            })
        } catch (error) {
            // 处理登录失败的逻辑
            ElMessage.error("保存失败！" + error.response.data);
        }
    }else{
        showMoveDialog.value = false;
    }
}
const delteFolder = () => {
    var message = "是否确认删除该文件夹?";
    ElMessageBox.confirm(message, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(async () => {
        try {
            await api.DeleteNoteFolder(folderId.value);
            ElMessage({
                message: '删除成功！',
                type: 'success',
            })
            router.back();
        }
        catch (error) {
            ElMessage.error("删除失败，请重试！" + error.response.data);
        }
    }).catch(() => {
    });
}
const addNote = () => {
    router.push({ path: "/NoteSave/0", query: { fid: folderId.value } });
}
</script>
<style scoped>
.el-form--inline .el-form-item {
    margin-right: 10px;
}

.el-form-item.asterisk-left {
    margin-bottom: 0px !important;
}
</style>