<template>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">卡片信息</span>
            <div class="d-tab d-center" style="margin: 10px 0">
                <span>当前{{ entityName == "cashcard" ? "余额" : "额度" }}：{{ amount }}</span>
            </div>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24">
            <div class="d-tab d-center">
                <el-button type="primary" @click="transferAmount">转账</el-button>
                <el-button type="primary" @click="transferAmount">充值</el-button>
                <el-button type="primary" @click="transferAmount">还款</el-button>
                <el-button type="primary" @click="changeAmount">调整{{ entityName == "cashcard" ? "余额" : "额度"
                    }}</el-button>
            </div>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24">
            <div class="d-tab" style="margin: 10px 0">
                <span class="dou-badge">近三次调额记录</span>
                <el-table :data="adjusts" stripe style="width: 100%" @row-click="showRowInfo">
                    <el-table-column prop="createdOn" label="调整时间" width="180" />
                    <el-table-column prop="amount" label="调整值" />
                </el-table>
            </div>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">近一月趋势</span>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24" style="height: 300px;">
            <v-chart class="chart" :option="options" autoresize />
        </el-col>
    </el-row>
    <el-dialog v-model="showAdjustWindow" :title="'调整' + (entityName == 'cashcard' ? '余额' : '额度')" width="90%">
        <el-form label-width="68px" :rules="rule" ref="formRef" :model="adjustForm">
            <el-form-item label="调整为" prop="Amount">
                <el-input v-model="adjustForm.Amount" placeholder="请输入调整后的值"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="adjustForm.Memo" type="textarea"></el-input>
            </el-form-item>
        </el-form>
        <span class="dialog-footer">
            <el-button type="primary" @click="changeAmountSubmit">
                提交
            </el-button>
        </span>
    </el-dialog>
    <el-dialog v-model="showTransferWindow" title="转账" width="90%">
        <el-form label-width="80px">
            <el-form-item label="接收账户">
                <el-select v-model="transferData.targetCard" filterable allow-create placeholder="请输入或选择">
                    <el-option v-for="item in transferCardList" :key="item.value" :label="item.label"
                        :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="接收方名">
                <el-input v-model="transferData.target" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="金额">
                <el-input v-model="transferData.amount" placeholder="请输入" type="number"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="transferData.memo" type="textarea" />
            </el-form-item>
        </el-form>
        <span class="dialog-footer">
            <el-button type="primary" @click="transferAmountSubmit">
                提交
            </el-button>
        </span>
    </el-dialog>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import api from '../../../api/api.js';
import dayjs from 'dayjs';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { use } from 'echarts/core';

import {
    LineChart
} from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    ToolboxComponent
} from 'echarts/components';
import {
    CanvasRenderer
} from 'echarts/renderers';
use([
    LineChart,
    GridComponent,
    TooltipComponent,
    ToolboxComponent,
    CanvasRenderer
]);
import VChart from 'vue-echarts';
export default {
    props: {
        entityName: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        }
    },
    components: {
        VChart // 注册ECharts组件，以便在模板中使用<e-charts>标签  
    },
    setup(props, context) {
        const adjusts = ref([]);
        // 使用 watch 监听 props.id 的变化  
        watch(() => props.id, (newVal, oldVal) => {
            console.log(`ID changed from ${oldVal} to ${newVal}`);
        });
        const amount = ref(0);
        //定义显示额度调整框
        const showAdjustWindow = ref(false);
        const showTransferWindow = ref(false);
        const adjustForm = ref({
            Amount: 0
        });
        const formRef = ref(null);
        const transferCardList = ref([]);//转账，卡列表
        const transferData = ref({});//转账，绑定输入
        const rule = ref({ Amount: [{ required: true, trigger: "blur", message: "请输入金额" }] });
        const changeAmount = () => {
            showAdjustWindow.value = true;
        }
        const transferAmount = () => {
            showTransferWindow.value = true;
        }
        const getRemainAmount = async () => {
            const cardInfo = await api.GetRemainAmount(props.entityName, props.id);
            adjusts.value = [];
            cardInfo.adjusts.forEach(element => {
                element.createdOn = dayjs(element.createdOn + "Z").format('YYYY-MM-DD HH:mm:ss');
                adjusts.value.push(element);
            });
            amount.value = cardInfo.remainAmount;
        }
        onMounted(async () => {
            await getRemainAmount();
            //获取卡片列表
            let cardList = await api.GetCardList();
            cardList.filter(m => m.type == "储蓄卡").forEach(m => {
                if (m.id != props.id) {
                    let tempObj = {};
                    var tempLabel = "";
                    var tempNo = "";
                    if (m.cardNo) {
                        if (m.cardNo.length > 4) {
                            // 如果字符串长度大于4，返回最后4位
                            tempNo = m.cardNo.slice(-4);
                        } else {
                            // 如果字符串长度在1到4之间，返回整个字符串
                            tempNo = m.cardNo;
                        }
                    }
                    if (tempNo != "") {
                        tempLabel = m.name + "(" + tempNo + ")";
                    } else {
                        tempLabel = m.name;
                    }
                    tempObj.label = tempLabel;
                    tempObj.value = m.id;
                    transferCardList.value.push(tempObj);
                }
            });
        })
        const { emit } = context;
        const transferAmountSubmit = async () => {
            let tempObj = {};
            tempObj = transferData.value;
            tempObj.cardId = props.id;
            try {
                await api.CardTransterSave(tempObj);
                ElMessage({
                    message: "操作成功",
                    type: 'success'
                })
                showTransferWindow.value = false;
                await getRemainAmount();
                emit("updateEvent", true);
            } catch (e) {
                console.log(e);
                ElMessage({
                    message: e.response.data,
                    type: 'error'
                })
            }
        }
        const changeAmountSubmit = () => {
            formRef.value.validate(async (valid) => {
                if (valid) {
                    let tempObj = {};
                    tempObj.CardId = props.id;
                    tempObj.Amount = adjustForm.value.Amount;
                    tempObj.Memo = adjustForm.value.Memo;
                    await api.CardAdjustSave(tempObj);
                    showAdjustWindow.value = false;
                    await getRemainAmount();
                    emit("updateEvent", true);
                }
            });
        }
        const router = useRouter();
        const showRowInfo = (e) => {
            let tempId = e.id;
            router.push('/pi/amountadjust/' + tempId);
        }
        //画图
        const chart = ref(null);

        const options = ref({
            title: {
                text: '折线图示例'
            },
            tooltip: {},
            xAxis: {
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {},
            series: [{
                name: '销量',
                type: 'line',
                data: [120, 200, 150]
            },
            {
                name: '销量1',
                type: 'line',
                data: [null, null, null, 80, 70, 110, 130]
            }]
        });
        return {
            //显式使用setup时，所有的都需要定义
            amount,
            adjusts,
            showAdjustWindow,
            showTransferWindow,
            adjustForm,
            changeAmount,
            changeAmountSubmit,
            transferAmount,
            transferAmountSubmit,
            showRowInfo,
            chart,
            options,
            transferCardList,
            transferData,
            rule,
            formRef
        };
    }
};
</script>