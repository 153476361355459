<template>
    <div class="drag-container">
        <VueDraggableNext v-model="items" group="items" @end="onEnd" @change="onChange" @start="onDragStart">
            <el-row v-for="(item, index) in items" :key="item.name" class="drag-item"
                :class="{ dragging: isDragging === index }">
                {{ item.name }}
            </el-row>
        </VueDraggableNext>
        <!-- <div v-if="insertIndex !== null" class="drag-insert-line" :style="{ top: `${insertIndex * 50}px` }"></div> -->
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';

const items = ref([
    { name: 'Item 1' },
    { name: 'Item 2' },
    { name: 'Item 3' },
    { name: 'Item 4' },
]);
const isDragging = ref(null);
const insertIndex = ref(null);

const onEnd = (event) => {
    console.log('Drag ended:', event);
    isDragging.value = null;
    insertIndex.value = null;
};

const onChange = (event) => {
    console.log('Drag onChange:', event);
    insertIndex.value = event.newIndex;
};

const onDragStart = (event) => {
    console.log('Drag onDragStart:', event);
    isDragging.value = event.oldIndex;
};
</script>

<style>
.drag-container {
    width: 300px;
    margin: 0 auto;
    position: relative;
}

.drag-item {
    padding: 10px;
    margin: 5px 0;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: move;
    transition: background-color 0.3s;
}

.drag-item.dragging {
    background-color: #d0e1ff; /* 高亮颜色 */
}

.drag-insert-line {
    height: 2px;
    background-color: #007bff; /* 插入线的颜色 */
    position: absolute;
    width: 100%;
}
</style>
