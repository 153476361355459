<template>
  <div>
    <LuckyWheel width="300px" height="300px" ref="wheel" :prizes="prizes" :buttons="buttons" @start="onStart"
      @end="onEnd" />
    <button @click="startLottery">开始抽奖</button>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { LuckyWheel } from '@lucky-canvas/vue';
import api from '../../../api/api.js';

const prizes = ref([]);
const buttons = ref([{ radius: '50px', background: '#ffdeec' }]);

const wheel = ref(null);

const fetchWheelData = async () => {
  try {
    const response = await api.GetWheelConfig(); // 获取配置
    prizes.value = response.prize.map((item) => ({
      name: item.name,
      background: item.color,
      fonts: [{ text: item.name, top: '30%' }],
    }));
  } catch (error) {
    console.error('获取转盘配置失败', error);
  }
};

const startLottery = async () => {
  try {
    const response = await api.DrawLottery(); // 后台计算中奖结果
    const prizeIndex = response.prizeIndex;
    wheel.value.play(prizeIndex);
    // 模拟旋转时间，确保转盘旋转一段时间后再停止
    setTimeout(() => {
      // 传入中奖索引停止转盘
      wheel.value.stop(prizeIndex);
    }, 3000); // 假设旋转3秒后停止
  } catch (error) {
    console.error('抽奖失败', error);
  }
};

const onStart = () => console.log('转盘开始旋转');
const onEnd = (prize) => console.log('抽奖结束，中奖：', prize.name);

onMounted(fetchWheelData);
</script>

<style scoped>
button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ff8a65;
  color: white;
  border: none;
  cursor: pointer;
}
</style>