<template>
    <el-timeline>
        <el-timeline-item timestamp="2024/06/10" placement="top">
            <el-card>
                <p>勉强可以进行账单保存了；实现了link穿透；-》还不能编辑，也不能计算账户余额。-》还好。</p>
            </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2024/05/28" placement="top">
            <el-card>
                <p>解决了空白页问题。-》修改了导航显示。-》还好!</p>
            </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2024/05/27" placement="top">
            <el-card>
                <p>今天本来是打算账单部分实现增操作。结果只是到创建卡页面。还欠一个公共保存页面与账单保存页面，进度偏低。-》一般!</p>
            </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2024/05/22" placement="top">
            <el-card>
                <p>添加了自定义特性。-》添加了getdb方法。-》添加了测试项目与getdb的测试方法。-》在前端初始化main.js调用getdb获取表结构，并存储到vuex。-》并在页面初始化时获取到这个值作为表结构参考。-》完工。-》Nice!</p>
            </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2023/09/23" placement="top">
            <el-card>
                <p>改为了侧边菜单。</p>
            </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2023/09/09" placement="top">
            <el-card>
                <p>通用增刪改查研究成功！</p>
            </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2023/09/09" placement="top">
            <el-card>
                <p>通用保存，通用列表已经成功！接下来是通用详情</p>
            </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2023/09/08" placement="top">
            <el-card>
                <p>通用保存初步成功！接下来是dexie</p>
            </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2023/09/07" placement="top">
            <el-card>
                <p>开始尝试创建通用表单啦</p>
                <p>ps:不能解决问题就绕过问题。</p>
            </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2023/09/04" placement="top">
            <el-card>
                <p>初步搞定了路由！</p>
            </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2023/09/03" placement="top">
            <el-card>
                <p>模块化第一枪。</p>
            </el-card>
        </el-timeline-item>
    </el-timeline>
</template>