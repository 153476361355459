<template>
    <button v-show="false" @click="saveChildPlan"></button>
    <button v-show="false" @click="showRowInfo"></button>
    <el-row>
        <el-col :span="24">
            <GlobalBreadcrumb :name="'日志'"></GlobalBreadcrumb>
        </el-col>
    </el-row>
    <el-row style="margin-top:10px">
        <span class="dou-badge">目标执行</span>
        <el-col :span="24" v-for="(item, index) in execChild" v-bind:key="item.name">
            <div style="margin: 5px 0" @click="goUrl(item.id)">{{ item.name }}</div>
            <el-form>
                <el-form-item label="状态">
                    <el-select v-model="item.status" placeholder="请选择">
                        <el-option v-for="item in selectStatus" :key="item.value" :label="item.key"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input type="textarea" rows="2" v-model="item.memo" placeholder="备注" />
                </el-form-item>
            </el-form>
            <hr v-if="index == execChild.length - 1" />
        </el-col>
    </el-row>
    <el-row style="margin-top:10px">
        <span class="dou-badge">习惯打卡</span>
        <el-col :span="24" v-for="(item, index) in habitChild" v-bind:key="item.name">
            <div style="margin: 5px 0">{{ item.name }}</div>
            <el-form>
                <el-form-item label="打卡" v-if="item.type == 0">
                    <el-switch v-model="item.value" />
                </el-form-item>
                <el-form-item label="时间" v-else-if="item.type == 1">
                    <el-time-picker v-model="item.value" style="width: 100%" :editable="false" :format="'HH:mm'" />
                </el-form-item>
                <el-form-item v-if="item.type == 1">
                    <el-input type="textarea" rows="2" v-model="item.memo" placeholder="备注" />
                </el-form-item>
            </el-form>
            <hr v-if="index == habitChild.length - 1" />
        </el-col>
    </el-row>
    <el-row style="margin-top:10px">
        <span class="dou-badge">总结</span>
        <el-col :span="24">
            <el-form>
                <el-form-item>
                    <el-input type="textarea" rows="2" v-model="formInfo.memo" placeholder="总结内容" />
                </el-form-item>
            </el-form>
        </el-col>
    </el-row>
    <el-row style="margin-top:10px" v-if="today">
        <el-col :span="24">
            <span class="dialog-footer">
                <el-button type="primary" @click="submitDialog">
                    提交
                </el-button>
            </span>
        </el-col>
    </el-row>
</template>
<script setup>
import { ref, onMounted, defineEmits, defineProps, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import api from '../../../api/api.js';
const props = defineProps(['entityName', "id"]);
const router = useRouter();
const store = useStore();
const emit = defineEmits(['updateEvent']);
const execChild = ref([]);//执行列表
const habitChild = ref([]);//执行列表
const formInfo = ref({});
const choosedDate = ref(new Date());
const today = ref(true);
const selectStatus = ref([]);
onMounted(() => {
    pageInit();
    initEnum(["Status"]);
})
watch(() => props.id, () => {
    pageInit();
});
watch(() => choosedDate, () => {
    pageInit();
});
const pageInit = async () => {
    let tempExtData = await api.GetDaily();
    execChild.value = tempExtData.exec;
    if (tempExtData.diary == null) {
        tempExtData.habit.forEach(m => {
            if (m.type == 0) {
                m.value = true;
            }
        });
    } else {
        formInfo.value = tempExtData.diary;
    }
    habitChild.value = tempExtData.habit;
    formInfo.value.date = tempExtData.date;
}
const initEnum = async (enums) => {
    if (enums.length > 0) {
        //查询所需枚举
        const enumList = await api.GetGenericEnum(enums);
        // 处理登录成功的逻辑
        if (enumList.length > 0) {
            enumList.forEach(m => {
                selectStatus.value = m.data;
            });
        }
    }
}
const saveChildPlan = () => {
    router.push('/ps/plan/0?pid=' + props.id);
}
const showRowInfo = (e) => {
    let tempId = e.id;
    router.push('/pi/plan/' + tempId);
    emit("updateEvent", true);
    //切换标签页
    store.commit('generic/SET_CHILDUPDATETAB', true);
}
const getTimeStr = (time) => {
    var timeStr = time.toString();
    if (time < 10) {
        timeStr = "0" + timeStr;
    }
    return timeStr;
}
const submitDialog = async () => {
    let execArr = [];
    execChild.value.forEach(m => {
        let tempObj = {};
        tempObj.Id = m.id;
        tempObj.Status = m.status;
        tempObj.Memo = m.memo;
        execArr.push(tempObj);
    });
    let habitArr = [];
    habitChild.value.forEach(m => {
        let tempObj = {};
        tempObj.HabitId = m.id;
        tempObj.Value = String(m.value);
        if (m.type == 1) {
            if (typeof m.value === "string") {
                m.value = new Date(m.value);
            }
            tempObj.Value = getTimeStr(m.value.getHours()) + ":" + getTimeStr(m.value.getMinutes()) + ":00";
        }
        tempObj.Memo = m.memo;
        habitArr.push(tempObj);
    });
    var data = {
        habitHistories: habitArr,
        goalExecutions: execArr,
        diary: {
            memo: formInfo.value.memo
        },
        date: formInfo.value.date
    }
    try {
        await api.SaveDaily(data);
        ElMessage({
            message: "操作成功",
            type: 'success'
        })
        router.back();
    } catch (e) {
        console.log(e);
        ElMessage({
            message: e.response.data,
            type: 'error'
        })
    }
}
const goUrl = (id) => {
    router.push('/pi/goalexecution/' + id);
}
</script>
<style scoped>
hr {
    border-top: #dcdfe6;
}
</style>