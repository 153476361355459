<template>
    <div v-if="!getUser">
        <el-alert title="2024-08-31" type="warning" description="盈虚者如彼，而卒莫消长也。" />
    </div>
    <div class="div-container" v-if="!getUser">
        <img src="/images/login/login_bd.jpg" />
    </div>
    <el-form style="width: 80%;margin:20px auto 0;" v-if="!getUser">
        <el-form-item>
            <el-input v-model="loginform.username" placeholder="请输入账号" />
        </el-form-item>
        <el-form-item>
            <el-input v-model="loginform.password" placeholder="请输入密码" />
        </el-form-item>
    </el-form>
    <el-descriptions class="margin-top" title="" :column="1" :size="size" border style="margin: 20px 0;" v-if="getUser">
        <el-descriptions-item>
            <template #label>
                <div class="cell-item">
                    <el-icon :style="iconStyle">
                        <user />
                    </el-icon>
                    账号
                </div>
            </template>
            {{ getUser.userName }}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                <div class="cell-item">
                    <el-icon :style="iconStyle">
                        <user />
                    </el-icon>
                    昵称
                </div>
            </template>
            {{ getUser.nickName }}
        </el-descriptions-item>
        <el-descriptions-item>
            <template #label>
                <div class="cell-item">
                    <el-icon :style="iconStyle">
                        <user />
                    </el-icon>
                    登录时间
                </div>
            </template>
            {{ formattedLoginTime }}
        </el-descriptions-item>
    </el-descriptions>
    <div class="div-container">
        <span class="dialog-footer">
            <el-button type="primary" @click="login" v-if="!getUser">
                登录/注册
            </el-button>
            <el-button type="primary" @click="handleLogout" v-if="getUser">
                注销
            </el-button>
        </span>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ElMessage } from 'element-plus'
export default {
    computed: {
        ...mapGetters('user', ['getLoginTime', 'getUser']),
        formattedLoginTime() {
            if (this.getLoginTime) {
                const date = new Date(this.getLoginTime);
                const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'Asia/Shanghai' };
                return new Intl.DateTimeFormat('zh-CN', options).format(date);
            } else {
                return '未登录';
            }
        }
    },
    props: {

    },
    data() {
        return {
            loginform: {}
        }
    },
    created() {

    },
    watch: {

    },
    components: {

    },
    methods: {
        ...mapActions('user', ['loginUser']), // 注意这里使用了 'user' 命名空间
        ...mapActions('user', ['setLogoutCallback', 'logout']),
        async login() {
            const username = this.loginform.username;
            const password = this.loginform.password;
            if(username==null){
                ElMessage.error("请输入账号");
                return;
            }
            if(password==null){
                ElMessage.error("请输入密码");
                return;
            }

            const loginResult = await this.$store.dispatch('user/loginUser', { username, password });

            if (loginResult==true) {
                // 登录成功，跳转到首页或其他页面
                this.$router.push('/');
            } else {
                // 登录失败，处理错误逻辑
                ElMessage.error(loginResult.response.data);
            }
        },
        handleLogout() {
            // 设置回调函数，在 logout 完成后执行(可以简化写法，先留着吧，供学习结构使用)
            this.setLogoutCallback(() => {
                // 使用 Vue Router 跳转到登录页面
                this.$router.push('/login');
            });

            // 调用 logout 方法
            this.logout();
        },
    }
}
</script>
<style scoped>
.div-container {
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中，但前提是div是flex容器 */
    overflow: hidden;
    /* 如果需要，可以隐藏超出div的图片部分 */
    width: 80%;
    margin: 10px auto;
}

.div-container img {
    max-width: 100%;
    /* 图片最大宽度不超过div的宽度 */
    /* 图片最大高度不超过div的高度，但注意这可能会导致图片比例失真 */
    /* 或者只设置max-width: 100%; 保持图片比例 */
}
</style>