<template>
    <el-row>
        <el-col :span="24">
            <GlobalBreadcrumb :name="entityName" prefixStr="保存"></GlobalBreadcrumb>
        </el-col>
    </el-row>
    <el-row style="margin-top:20px ;">
        <el-col :span="24">
            <GenericSave :entityName="entityName" :id="id" type="p" @formResult="handleFormResult"></GenericSave>
        </el-col>
    </el-row>
</template>
<script>
import { useRoute } from 'vue-router';
import GenericSave from './GenericSave.vue';
export default {
    data() {
        return {
            entityName: '',
            id: ""
        }
    },
    created() {
        const route = useRoute();
        this.entityName = route.params.entityName;
        this.id = route.params.id;
    },
    // 监视路由参数的变化，并更新 entityName
    watch: {
        'route.params.entityName'(newEntityName) {
            this.entityName = newEntityName;
        },
        'route.params.id'(newId) {
            this.entityName = newId;
        }
    },
    components: {
        GenericSave
    },
    methods: {
        handleFormResult(result) {
            if (result) {
                var backUrlName = this.$route.query.backUrlName;
                if (backUrlName != null) {
                    this.$router.replace({ name: backUrlName });
                } else {
                    window.history.go(-1);
                }
            }
        }
    }
}
</script>