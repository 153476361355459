<template>
    <el-form label-width="120px">
        <el-form-item label="Instant delivery">
            <el-switch v-model="isLocalSave" />
        </el-form-item>
    </el-form>
</template>
<script>
export default {
    props: {

    },
    data() {
        return {
            isLocalSave:true
        }
    },
    created() {

    },
    watch: {

    },
    components: {

    },
    methods: {
        GDAScheduleList: function () {
            
        }
    }
}
</script>