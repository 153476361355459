<template>
    <el-row v-show="false">
        <el-col :col="24">
            <span class="dou-badge">当前信息</span>
            <el-descriptions class="margin-top" :column="1" border>
                <el-descriptions-item v-for="item in desList" v-bind:key="item">
                    <template #label>
                        <div class="cell-item" style="min-width: 60px;">
                            {{ item.display }}
                        </div>
                    </template>
                    <span v-html="item.name" :class="{ 'd-link': item.id != null }" @click="gotoLink(item.id)"></span>
                </el-descriptions-item>
            </el-descriptions>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">功能列表</span>
            <div class="d-tab d-center">
                <el-button type="primary" @click="saveExec">调整状态</el-button>
            </div>
        </el-col>
    </el-row>
    <el-dialog v-model="showSaveExec" title="更改执行状态" width="90%">
        <el-form :model="form" label-width="68px" ref="formRef">
            <el-form-item label="状态" prop="Status">
                <el-select v-model="form.status" placeholder="请选择">
                    <el-option v-for="item in selectStatus" :key="item.value" :label="item.key" :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form label-width="68px">
                <el-form-item label="备注">
                    <el-input rows="3" v-model="form.memo" type="textarea" />
                </el-form-item>
            </el-form>
        </el-form>
        <span class="dialog-footer">
            <el-button type="primary" @click="saveExecSubmit">
                提交
            </el-button>
        </span>
    </el-dialog>
</template>
<script setup>
import { ref, defineProps, onMounted, watch, defineEmits } from 'vue';
import { ElMessage } from 'element-plus';
const props = defineProps(['entityName', "id"]);
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import api from '../../../api/api.js';
const emit = defineEmits(['updateEvent']);
const store = useStore();
const desList = ref([]);//描述列表
const info = ref({});
const showSaveExec = ref(false);
const form = ref({});
const router = useRouter();
const selectStatus = ref([]);
onMounted(() => {
    pageInit();
    //初始化Enum
    initEnum(["Status"]);
})
watch(() => props.id, () => {
    pageInit();
});
const pageInit = async () => {
    let tempExtData = await api.GetGoalExecExt(props.id);
    info.value = tempExtData.info;
    let tempdes = [];
    if (tempExtData.goalId != null) {
        tempdes.push({ display: "目标", name: tempExtData.goal, id: tempExtData.goalId });
    }
    tempdes.push({ display: "状态", name: tempExtData.info.statusName });
    if (tempExtData.info.memo != null) {
        tempdes.push({ display: "备注", name: tempExtData.info.memo });
    }
    form.value.status = tempExtData.info.status;
    if (tempExtData.info.memo != null) {
        form.value.memo = tempExtData.info.memo;
    }
    desList.value = tempdes;
}
const saveExec = () => {
    showSaveExec.value = true;
}
const saveExecSubmit = async () => {
    let tempObj = {};
    tempObj.id = props.id;
    tempObj.status = form.value.status;
    tempObj.memo = form.value.memo;
    try {
        await api.UpdateGoalExecStatus(tempObj);
        ElMessage({
            message: "操作成功",
            type: 'success'
        })
        showSaveExec.value = false;
        pageInit();
        emit("updateEvent", true);
        //切换标签页
        store.commit('generic/SET_CHILDUPDATETAB', true);
    } catch (e) {
        console.log(e);
        ElMessage({
            message: e.response.data,
            type: 'error'
        })
    }
}
const gotoLink = (id) => {
    if (id != null) {
        router.push('/pi/goal/' + id);
    }
}
const initEnum = async (enums) => {
    if (enums.length > 0) {
        //查询所需枚举
        const enumList = await api.GetGenericEnum(enums);
        // 处理登录成功的逻辑
        if (enumList.length > 0) {
            enumList.forEach(m => {
                selectStatus.value = m.data;
            });
        }
    }
}
</script>
<style scoped>
.d-link {
    color: #409eff;
}
</style>