<template>
    <el-row class="sticky-div">
        <el-col :span="24" v-if="false">
            <div style="border: 1 solid black;margin: 10px;">
                <div>
                    <div style="display: flex;justify-content: space-between;padding: 0 20px;">
                        <div>
                            <span>岩月</span>
                        </div>
                        <div style="display: flex;" @click="goHref">
                            <span style="display: flex;margin-right: 5px;">
                                <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    fill="currentColor">
                                    <path
                                        d="M10 11V8L15 12L10 16V13H1V11H10ZM2.4578 15H4.58152C5.76829 17.9318 8.64262 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C8.64262 4 5.76829 6.06817 4.58152 9H2.4578C3.73207 4.94289 7.52236 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C7.52236 22 3.73207 19.0571 2.4578 15Z">
                                    </path>
                                </svg>
                            </span>
                            <span>登录</span>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="24">
            <div style="text-align: left;margin: 10px 20px;">
                <router-view></router-view>
            </div>
        </el-col>
    </el-row>
</template>
<script setup>
import { useRouter } from 'vue-router';
const route = useRouter();
const href = "/Login";
const goHref=()=>{
    route.push({path:href});
}
</script>
<style>
.sticky-div {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    /* 确保它在其他元素之上 */
}

.el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: none !important;
    color: black !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    background-color: white !important;
}

.flex-grow {
    flex-grow: 1;
}

a {
    text-decoration: none;
    color: black;
}

.el-menu-item a {
    width: 100%;
    text-align: left;
}
</style>