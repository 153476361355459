<template>
  <el-row>
    <el-col :col="24">
      <span class="dou-badge">功能列表</span>
      <div class="d-tab d-center">
        <el-button type="primary" @click="saveChildPlan">新建子计划</el-button>
        <el-button type="primary" @click="saveGoal">添加目标</el-button>
        <el-button type="primary" @click="updateStatus">调整状态</el-button>
      </div>
    </el-col>
  </el-row>
  <el-row>
    <el-col :col="24">
      <span class="dou-badge">子计划列表</span>
      <el-table :data="planChild" width="300" stripe @row-click="showRowInfo">
        <el-table-column prop="name" label="名称" />
        <el-table-column width="100" prop="starttime" label="开始时间" />
        <el-table-column width="100" prop="endtime" label="结束时间" />
      </el-table>
    </el-col>
  </el-row>
  <el-row>
    <el-col :col="24">
      <span class="dou-badge">目标一览</span>
      <div>
        <el-input v-model="filterText" style="width: 240px;margin-bottom: 3px;" placeholder="请输入搜索内容" />
        <div style="overflow-x: auto;">
          <el-tree ref="treeRef" class="filter-tree" :data="treeData" :props="defaultProps" default-expand-all
            :filter-node-method="filterNode" :expand-on-click-node="false" @node-click="goLeaf" />
        </div>
      </div>
    </el-col>
  </el-row>
  <el-dialog v-model="showSaveStatus" title="更改计划状态" width="90%">
        <el-form :model="form" label-width="68px" ref="formRef">
            <el-form-item label="状态">
                <el-select v-model="formStatus.status" placeholder="请选择">
                    <el-option v-for="item in selectStatus" :key="item.value" :label="item.key" :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form label-width="68px">
                <el-form-item label="备注">
                    <el-input rows="3" v-model="formStatus.memo" type="textarea" />
                </el-form-item>
            </el-form>
        </el-form>
        <span class="dialog-footer">
            <el-button type="primary" @click="submitStatus">
                提交
            </el-button>
        </span>
    </el-dialog>
</template>
<script setup>
import { ref, onMounted, defineEmits, defineProps, watch } from 'vue';
import { ElTree } from 'element-plus'
const props = defineProps(['entityName', "id"]);
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import api from '../../../api/api.js';
import dayjs from 'dayjs';
import { ElMessage } from 'element-plus';
const router = useRouter();
const store = useStore();
const selectStatus = ref([]);
const emit = defineEmits(['updateEvent']);
const planChild = ref([]);//子列表

//Tree
// 移除 TypeScript 接口定义  
const filterText = ref('');
const treeRef = ref(null); // 在 Vue 3 中，ref 初始值通常为 null 或具体的初始值  
const defaultProps = {
  children: 'children',
  label: 'label',
  class: 'class'
};
watch(filterText, (val) => {
  if (treeRef.value) {
    treeRef.value.filter(val);
  }
});
// 由于移除了 TypeScript 的类型定义，filterNode 函数的参数类型也不再明确  
// 但这不会影响 JavaScript 的运行  
const filterNode = (value, data) => {
  if (!value) return true;
  return data.label.includes(value);
};
// 数据同样不需要 TypeScript 类型定义  
const treeData = ref([]);

onMounted(() => {
  pageInit();
  initEnum(["Status"]);
})
watch(() => props.id, () => {
  pageInit();
});
const pageInit = async () => {
  let tempExtData = await api.GetPlanExt(props.id);
  let tempChild=[];
  tempExtData.child.forEach(element => {
    element.starttime = new Date(dayjs(element.starttime + "Z")).toLocaleDateString();
    element.endtime = new Date(dayjs(element.endtime + "Z")).toLocaleDateString();
    tempChild.push(element);
  });
  planChild.value=tempChild;
  treeData.value = tempExtData.childTree;
  formStatus.value=tempExtData.info;
}
const initEnum = async (enums) => {
    if (enums.length > 0) {
        //查询所需枚举
        const enumList = await api.GetGenericEnum(enums);
        // 处理登录成功的逻辑
        if (enumList.length > 0) {
            enumList.forEach(m => {
                selectStatus.value = m.data;
            });
        }
    }
}
const saveChildPlan = () => {
  router.push('/ps/plan/0?pid=' + props.id);
}
const showRowInfo = (e) => {
  let tempId = e.id;
  router.push('/pi/plan/' + tempId);
  emit("updateEvent", true);
  //切换标签页
  store.commit('generic/SET_CHILDUPDATETAB', true);
}
const saveGoal = () => {
  router.push('/ps/goal/0?planid=' + props.id);
}
const goLeaf = (obj) => {
  var tempClass = obj.class;
  let tempId = obj.id;
  switch (tempClass) {
    case "d-tree-plan": {
      router.push('/pi/plan/' + tempId);
      emit("updateEvent", true);
      //切换标签页
      store.commit('generic/SET_CHILDUPDATETAB', true);
    } break;
    case "d-tree-goal": {
      router.push('/pi/goal/' + tempId);
    } break;
  }
}

//保存状态
const showSaveStatus=ref(false);
const formStatus=ref({});
const updateStatus=()=>{
    showSaveStatus.value=true;
}
const submitStatus=async()=>{
    let tempObj = {};
    tempObj.id = props.id;
    tempObj.status = formStatus.value.status;
    tempObj.memo = formStatus.value.memo;
    try {
        await api.UpdatePlanStatus(tempObj);
        ElMessage({
            message: "操作成功",
            type: 'success'
        })
        showSaveStatus.value = false;
        pageInit();
        emit("updateEvent", true);
        //切换标签页
        store.commit('generic/SET_CHILDUPDATETAB', true);
    } catch (e) {
        console.log(e);
        ElMessage({
            message: e.response.data,
            type: 'error'
        })
    }
}
</script>