<template>
    <el-descriptions class="margin-top" :column="1" :size="size" border>
        <el-descriptions-item v-for="item in attrs" v-bind:key="item">
            <template #label>
                <div class="cell-item" style="min-width: 60px;">
                    {{ item.display }}
                </div>
            </template>
            <span v-html="info[item.name]"></span>
        </el-descriptions-item>
    </el-descriptions>
</template>
<script>
import {mapState } from 'vuex';
import api from '../../../api/api.js'
export default {
    data() {
        return {
            entityObj: {},
            attrs: [],
            info: {}
        }
    },
    watch: {
        id(newValue) {
            console.log("GenericInfo的id变更:" + this.entityName + "|" + newValue);
            this.initBase();
        }
    },
    created() {
        const entityName = this.$route.query.entityName;  
        this.info.id = this.$route.query.id;  
        this.entityObj = this.douTable[entityName];
    },
    mounted() {
        if (this.info.id != null) {
            this.initBase();
        }
    },
    computed: {
        ...mapState('user', ['douTable'])
    },
    components: {

    },
    methods: {
        async initBase() {
            if (this.info.id != null) {
                var resopnse = await api.GetGenericDataById(this.entityObj.entityName,this.info.id);
                if (resopnse != null) {
                    var data = resopnse;
                    this.tableData = [];
                    var entityAttr = this.entityObj.attr.filter(m => m.visable != false);
                    for (var i = 0; i < entityAttr.length; i++) {
                        if (entityAttr[i].biz != null) {
                            var bizList = entityAttr[i].biz.filter(m => m.value == data[entityAttr[i].name]);
                            if (bizList.length > 0) {
                                var bizArr = bizList[0].show;
                                bizArr.forEach(element => {
                                    entityAttr.push(this.entityObj.attr.filter(m => m.name == element)[0]);
                                });
                            }
                        }
                    }
                    this.attrs = entityAttr;

                    //初始化数据
                    // var tempLinkList = [];
                    // var tempLinkResult = [];
                    var tempObj = {};
                    entityAttr.forEach(n => {
                        var ccName=n.name.charAt(0).toLowerCase() + n.name.slice(1); 
                        if (data[ccName] != null && data[ccName].toString().trim() != "") {
                            switch (n.type) {
                                case 'date': {
                                    tempObj[ccName] = data[ccName].split('T')[0];
                                } break;
                                case 'datetime': {
                                    if (data[ccName] != null && data[ccName] != "") {
                                        tempObj[ccName] = new Date(data[ccName]).toLocaleString();
                                    }
                                } break;
                                case "enum": {
                                    tempObj[ccName] = data[n.enum + "Name"];
                                } break;
                                // case 'selectData': {
                                //     var tempFilter = tempLinkList.filter(j => j.key == ccName);
                                //     if (tempFilter.length == 0) {
                                //         var tempLinkObj = {};
                                //         tempLinkObj.key = ccName;
                                //         tempLinkObj.value = [m[ccName]];
                                //         tempLinkList.push(tempLinkObj);
                                //     } else {
                                //         tempFilter[0].value.push(m[ccName]);
                                //     }
                                //     tempObj[ccName] = m[ccName];
                                // } break;
                                case 'textarea': {
                                    tempObj[ccName] = data[ccName].split('\n').join('</br>');
                                } break;
                                default: {
                                    tempObj[ccName] = data[ccName];
                                }
                            }
                        } else {
                            tempObj[ccName] = "-";
                        }
                    });
                    this.info = tempObj;
                }
            }
        }
    }
}
</script>