<template>
    <el-row>
        <el-col :span="24">
            <GlobalBreadcrumb :name="'保存收支'"></GlobalBreadcrumb>
        </el-col>
    </el-row>
    <el-row style="margin-top: 10px;">
        <el-col :span="24">
            <el-form :model="form" :rules="rules" label-width="80px" ref="formRef">
                <el-form-item label="收支类型" prop="InComeType">
                    <el-select v-model="form.InComeType" placeholder="请输入或选择" v-on:change="inComeTypeChange">
                        <el-option v-for="item in enumArr['InComeType']" :key="item.value" :label="item.key"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="名称" prop="Name">
                    <el-select v-model="form.Name" filterable allow-create placeholder="请输入或选择" clearable>
                        <el-option v-for="item in formNameList" :key="item.value" :label="item.label"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="账单类型" prop="BillType">
                    <el-select v-model="form.BillType" filterable allow-create placeholder="请输入或选择">
                        <el-option v-for="item in formBillTypeList" :key="item.value" :label="item.label"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="账户" prop="BankCardId">
                    <el-select v-model="form.BankCardId" filterable placeholder="请输入或选择">
                        <el-option v-for="item in formCardList" :key="item.value" :label="item.label"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="金额" prop="Amount">
                    <el-input v-model="form.Amount" type="number" />
                </el-form-item>
                <el-form-item label="入账时间" prop="BillTime">
                    <el-date-picker :clearable="false" v-model="form.BillTime" :placeholder="入账时间" type="datetime"
                        style="width: 100%" />
                </el-form-item>
                <el-form-item label="场景" prop="BillScence">
                    <el-select v-model="form.BillScence" placeholder="请输入或选择" v-on:change="BillScenceChange">
                        <el-option v-for="item in enumArr['BillScence']" :key="item.value" :label="item.key"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="平台" prop="Platform" v-if="form.BillScence == 1">
                    <el-select v-model="form.Platform" filterable allow-create placeholder="请输入或选择">
                        <el-option v-for="item in formPlatformList" :key="item.value" :label="item.label"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="地点" v-if="form.BillScence == 2">
                    <el-input v-model="form.Place" />
                </el-form-item>
                <el-form-item label="同伴" v-if="form.BillScence == 2">
                    <el-input v-model="form.People" />
                </el-form-item>
                <el-form-item label="更多">
                    <el-switch v-model="form.more" />
                </el-form-item>
                <div v-if="form.more">
                    <el-form-item label="对方">
                        <el-input v-model="form.TargetObject" />
                    </el-form-item>
                    <el-form-item label="对方账户">
                        <el-input v-model="form.TargetBankNo" />
                    </el-form-item>
                    <el-form-item label="备注" prop="memo">
                        <el-input v-model="form.Memo" type="textarea" />
                    </el-form-item>
                </div>
            </el-form>
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="24">
            <span class="dialog-footer">
                <el-button type="primary" @click="submitForm">
                    提交
                </el-button>
            </span>
        </el-col>
    </el-row>
    <el-dialog v-model="showSaveCard" title="操作提醒" width="90%" :show-close="false" :close-on-click-modal="false"
        :close-on-press-escape="false">
        <el-row>
            <el-col :span="24">
                <p>请先设置一个银行卡作为入账账户。</p>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-button type="primary" @click="goUrl('/ps/cashcard')">储蓄卡</el-button>
                <el-button type="primary" @click="goUrl('/ps/creditcard')">信用卡</el-button>
            </el-col>
        </el-row>
    </el-dialog>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import api from '../../../api/api.js';
import { ElMessage } from 'element-plus';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
export default {
    data() {
        return {
            showSaveCard: false,
            localId: null,//因为props的id不可直接修改
            selectList: [],//下拉框组
            formValues: [],
            groups: [],//用来存组
            form: {
                Name: "",
                InComeType: 1,
                BillTime: new Date(),
                more: false,
                BillScence: 1
            },//表单rules: {},//验证规则
            entityObj: {},//实体
            enumArr: {},//枚举数组
            formCardList: [],//卡片Select
            formBillTypeList: [],//账单类型Select
            formNameList: [],//账单名称Select
            formInComeType: [],//收支类型Select
            formPlatformList: [],//平台列表
            billExistInfo: {},//查询已存在类型
        }
    },
    async created() {
        let tempId = this.$route.params.id;
        //新打开时和info控件打开时需要初始化控件
        if (tempId != null) {
            this.localId = tempId;
        }
        var entity = this.douTable["bill"];
        this.entityObj = entity;
        // this.initEnum();
        this.dynamicUpdateRules();

    },
    async mounted() {
        //检查账户
        var accountList = await api.GetCardList();
        if (accountList.length == 0) {
            this.showSaveCard = true;
        }
        //初始下拉框
        //账户列表
        accountList.forEach(m => {
            var tempObj = {};
            var tempLabel = "";
            var tempNo = "";
            if (m.cardNo) {
                if (m.cardNo.length > 4) {
                    // 如果字符串长度大于4，返回最后4位
                    tempNo = m.cardNo.slice(-4);
                } else {
                    // 如果字符串长度在1到4之间，返回整个字符串
                    tempNo = m.cardNo;
                }
            }
            if (tempNo != "") {
                tempLabel = m.name + "(" + tempNo + ")";
            } else {
                tempLabel = m.name;
            }
            tempObj.label = tempLabel;
            tempObj.value = m.id;
            this.formCardList.push(tempObj);
        });
        this.billExistInfo = await api.GetBillExistInfo();
        this.billExistInfo.out.name.forEach(m => {
            this.formNameList.push(m);
        });
        this.billExistInfo.out.billType.forEach(m => {
            this.formBillTypeList.push(m);
        });
        this.billExistInfo.out.billType.forEach(m => {
            this.formBillTypeList.push(m);
        });
        this.billExistInfo.platform.forEach(m => {
            this.formPlatformList.push(m);
        });
        //初始化Enum
        await this.initEnum(["InComeType", "BillScence"]);
        //初始化UI内容
        await this.initData();
    },
    beforeUnmount() {
        console.log('GenericSave|beforeUnmount');
    },
    computed: {
        ...mapState('user', ['infoId', 'douTable'])
    },
    watch: {
        id() {
            this.initData();
        },
        infoId(newVal) {
            console.log("InfoId变更|" + newVal);
            this.localId = newVal;
            this.initData();
        },
        "form.Name"(newVal) {
            let sameOrder = this.billExistInfo.sameOrder.find(m => m.name == newVal);
            if (newVal != null && sameOrder != null) {
                this.form.BillType = sameOrder.billType;
                this.form.Amount = sameOrder.amount;
                this.form.BillScence = sameOrder.billScence;
                this.form.BankCardId = sameOrder.bankCardId;
                switch (sameOrder.billScence) {
                    case 1: {
                        this.form.Platform = sameOrder.platform;
                    } break;
                    case 2: {
                        this.form.Place = sameOrder.place;
                    } break;
                }
                //处理时间，类比当前的时间
                // 解析时间字符串 sameOrder.billTime
                let [hours, minutes, seconds] = sameOrder.billTime.split(':').map(Number);
                // 使用 dayjs 创建一个 UTC 时间的对象（从当前日期的零点开始）
                let utcTime = dayjs.utc().set('hour', hours).set('minute', minutes).set('second', seconds);
                // 转换为本地时区的时间
                let localDate = utcTime.local();
                // 将转换后的时间赋值给表单
                this.form.BillTime = localDate;
            } else {
                this.form.BillType = "";
                this.form.Amount = "";
                this.form.BillScence = "";
                this.form.BankCardId = "";
                this.form.BillTime = new Date();
            }
        }
    },
    components: {

    },
    methods: {
        ...mapMutations('user', ['SET_SAVE_STATUS', 'SET_REFRESH_LIST', 'SET_INFO_ID']),
        handleInputChange(values) {
            this.formValues = values;
            console.log(values);
        },
        //初始化方法
        async initEnum(enums) {
            if (enums.length > 0) {
                //查询所需枚举
                const enumList = await api.GetGenericEnum(enums);
                // 处理登录成功的逻辑
                if (enumList.length > 0) {
                    enumList.forEach(m => {
                        this.enumArr[m.name] = m.data;
                    });
                }
            }
        },
        async initData() {
            console.log("Save初始化|" + this.localId);
            if (this.localId != null && this.localId != "0") {
                var resopnse = await api.GetGenericDataById(this.entityObj.entityName, this.localId);
                if (resopnse != null) {
                    var data = resopnse;
                    data.BillTime = new Date(dayjs(data.BillTime + "Z").format("YYYY-MM-DD HH:mm:ss"));
                    this.form = data;
                }
            } else {
                if (this.localId == null) {
                    this.form = {};
                }
            }
        },
        changeValue() {

        },
        dynamicUpdateRules() {
            var tempRules = {};
            this.entityObj.attr.forEach(element => {
                var tempRule = {
                    required: true,
                    trigger: "blur"
                };
                switch (element.type) {
                    case "input":
                    case "textarea":
                    case "date":
                    case "number":
                    case "time":
                    default: {
                        if (element.required == true) {
                            tempRule.message = "请输入" + element.display;
                        }
                    } break;
                    case "selectData":
                    case "datetime":
                    case "enum": {
                        if (element.required == true) {
                            tempRule.message = "请选择" + element.display;
                        }
                    } break;
                }
                if (element.required) {
                    tempRules[element.name] = [tempRule];
                }
            });
            this.rules = tempRules;
        },
        async submitForm() {
            console.log("保存表单");
            var self = this;
            this.$refs.formRef.validate(async (valid) => {
                if (valid) {
                    var tempObj = {};
                    Object.keys(self.form).forEach(m => {
                        tempObj[m] = self.form[m];
                    });
                    try {
                        try {
                            await api.BillSave(tempObj);
                            this.$router.replace({ name: "Bill" });
                        } catch (error) {
                            // 处理登录失败的逻辑
                            ElMessage.error("保存失败！" + error);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    console.log('表单验证失败');
                    return false;
                }
            });
        },
        goUrl(url) {
            this.showSaveCard = false;
            this.$nextTick(() => {
                this.$router.push({ path: url, query: { backUrlName: "BillSave" } });
            });
        },
        inComeTypeChange() {
            let tempValue = this.form.InComeType;
            this.formNameList = [];
            this.formBillTypeList = [];
            if (tempValue == "1") {
                this.billExistInfo.out.name.forEach(m => {
                    this.formNameList.push(m);
                });
                this.billExistInfo.out.billType.forEach(m => {
                    this.formBillTypeList.push(m);
                });
            } else {
                this.billExistInfo.in.name.forEach(m => {
                    this.formNameList.push(m);
                });
                this.billExistInfo.in.billType.forEach(m => {
                    this.formBillTypeList.push(m);
                });
            }
        }
    }
}
</script>
<style scoped></style>