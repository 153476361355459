<template>
    <el-row style="margin-top: 10px;">
        <el-col :span="24">
            <div v-for="paragraph in sortedParagraphs" :key="paragraph.id" class="paragraph-container">
                <!-- 代码段：显示行号、语言名和复制按钮 -->
                <pre v-if="isCodeParagraph(paragraph.type)" class="line-numbers"><code ref="codeRef" :class="[getCodeClass(paragraph.type)]" v-html="paragraph.content"></code>
                </pre>
                <!-- 普通段落 -->
                <p v-else>{{ paragraph.content }}</p>
            </div>
        </el-col>
    </el-row>
    <hr />
    <el-row>
        <el-col :span="24">
            <div style="margin: 3px 0;">
                <span class="dou-badge-nohref">评论留言(666)</span>
            </div>
            <div>
                <el-input type="textarea" rows="5"></el-input>
            </div>
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="24">
            <div style="border: 1px solid #FFFFFF;">
                <span style="font-weight: bold;">测试：</span><span>这是我的评论，还是第一次评论，好紧张，哈哈哈哈。</span>
                <span style="font-size: 11px;color: gray;margin: 0 3px;">刚刚</span>
                <span style="font-size: 11px;color: gray;margin: 0 3px;">回复</span>
                <span style="font-size: 11px;color: gray;margin: 0 3px;">
                    <svg width="11" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path
                            d="M12.001 4.52853C14.35 2.42 17.98 2.49 20.2426 4.75736C22.5053 7.02472 22.583 10.637 20.4786 12.993L11.9999 21.485L3.52138 12.993C1.41705 10.637 1.49571 7.01901 3.75736 4.75736C6.02157 2.49315 9.64519 2.41687 12.001 4.52853ZM18.827 6.1701C17.3279 4.66794 14.9076 4.60701 13.337 6.01687L12.0019 7.21524L10.6661 6.01781C9.09098 4.60597 6.67506 4.66808 5.17157 6.17157C3.68183 7.66131 3.60704 10.0473 4.97993 11.6232L11.9999 18.6543L19.0201 11.6232C20.3935 10.0467 20.319 7.66525 18.827 6.1701Z">
                        </path>
                    </svg>
                </span>
                <div style="border: 1px solid #FFFFFF;margin-left: 30px;">
                    <span style="font-weight: bold;">测试1：</span><span>楼上你好啊</span>
                    <span style="font-size: 11px;color: gray;margin: 0 3px;">刚刚</span>
                    <span style="font-size: 11px;color: gray;margin: 0 3px;">回复</span>
                </div>
            </div>
        </el-col>
    </el-row>
</template>
<script setup>
import { ref, onMounted, defineProps, nextTick } from 'vue';
// import { useRouter } from 'vue-router';
import api from '../../../api/api.js';
// import { ElMessage } from 'element-plus';
// import { ElMessageBox } from 'element-plus';

const info = ref({});
const paragraphs = ref([]);
const sortedParagraphs = ref([]);
const codeRef = ref(null);
// const route = useRouter();

// 支持的语言类型与对应的 PrismJS class
const supportedLanguages = {
    'C#': 'language-csharp',
    'JavaScript': 'language-javascript',
    'Java': 'language-java',
    'HTML': 'language-markup',
    // 这里可以继续添加其他支持的语言
};

const props = defineProps(["id"]);
onMounted(async () => {
    var infoResult = await api.GetShareNoteById(props.id);
    info.value = infoResult;
    fetchParagraphs();
})

// 判断是否为代码段
const isCodeParagraph = (type) => !!supportedLanguages[type];

// 根据类型返回对应的代码高亮 class
const getCodeClass = (type) => supportedLanguages[type] || '';

const sortParagraphs = (paragraphs) => {
    // 1. 寻找起始段落（prevId 为 null）
    const startParagraph = paragraphs.find(p => p.prevId === null);
    if (!startParagraph) {
        console.error("没有找到起始段落！");
        return [];
    }

    // 2. 按顺序连接段落
    const sorted = [];
    let currentParagraph = startParagraph;
    while (currentParagraph) {
        sorted.push(currentParagraph);
        currentParagraph = paragraphs.find(p => p.id === currentParagraph.nextId);
    }

    return sorted;
};

const fetchParagraphs = async () => {
    paragraphs.value = info.value.paragraphs;
    // 调用排序函数
    sortedParagraphs.value = sortParagraphs(paragraphs.value);
    // 数据更新后使用 PrismJS 渲染代码高亮
    await nextTick(); // 确保 DOM 更新完成
    const Prism = await import('prismjs')
    await import('prismjs/themes/prism-tomorrow.css')
    await import('prismjs/components/prism-javascript.min.js')
    await import('prismjs/components/prism-java.min.js')
    await import('prismjs/components/prism-csharp.min.js')
    await import('prismjs/components/prism-markup.min.js')
    await import('prismjs/plugins/line-numbers/prism-line-numbers.js')
    await import('prismjs/plugins/line-numbers/prism-line-numbers.css')
    await import('prismjs/plugins/toolbar/prism-toolbar.js')
    await import('prismjs/plugins/toolbar/prism-toolbar.css')
    await import('prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.js')

    Prism.highlightAll();
};
</script>
<style scoped>
/* 移除选中黄框 */
pre,
code {
    outline: none;
}
</style>