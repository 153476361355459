import { createStore } from 'vuex';//vue3
import user from './modules/user';
import generic from './modules/generic';
export default createStore({  
  modules: {  
    user,
    generic
  }  
});
// import {
//   createStore
// } from 'vuex';
// import userModule from './user';
// export default createStore({
//   modules: {
//     user: userModule
//     // ...其他模块
//   },
//   state: {
//     // 这里是你的应用状态
//     user: null,
//     isAuthenticated: false,
//   },
//   mutations: {
//     // 这里是修改状态的方法
//     setUser(state, user) {
//       state.user = user;
//       state.isAuthenticated = !!user;
//     },
//   },
//   actions: {
//     // 这里是异步操作，通常用于处理登录、注销等操作
//     loginUser({
//       commit
//     }, user) {
//       // 处理登录逻辑...
//       // 在登录成功后调用：
//       commit('setUser', user);
//     },
//     logoutUser({
//       commit
//     }) {
//       // 处理注销逻辑...
//       // 在注销成功后调用：
//       commit('setUser', null);
//     },
//   },
//   getters: {
//     // 这里是获取状态的方法
//     getUser: state => state.user,
//     isAuthenticated: state => state.isAuthenticated,
//   },
// });