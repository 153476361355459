<template>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">卡片信息</span>
            <div class="d-tab d-center" style="margin: 5px 0">
                <div>剩余额度：{{ amount }}</div>
            </div>
            <div class="d-tab d-center" style="margin: 5px 0">
                <div>未入账：{{ unDebitAmount }}</div>
            </div>
            <div class="d-tab d-center" style="margin: 5px 0">
                <div>已入账：{{ debitAmount }}</div>
            </div>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24">
            <div class="d-tab d-center">
                <el-button type="primary" @click="changeAmount">调整额度</el-button>
            </div>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24">
            <div class="d-tab" style="margin: 10px 0">
                <span class="dou-badge">近三次调额记录</span>
                <el-table :data="adjusts" stripe style="width: 100%" @row-click="showRowInfo">
                    <el-table-column prop="createdOn" label="调整时间" width="155" />
                    <el-table-column prop="amount" label="未入账" />
                    <el-table-column prop="debitAmount" label="已入账" />
                </el-table>
            </div>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">近一月趋势</span>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24" style="height: 300px;">
            <v-chart class="chart" :option="options" autoresize />
        </el-col>
    </el-row>
    <el-dialog v-model="showAdjustWindow" title="调整额度" width="90%">
        <el-form label-width="68px">
            <el-form-item label="未入账">
                <el-input v-model="adjustAmount" placeholder="请输入调整后的值"></el-input>
            </el-form-item>
            <el-form-item label="已入账">
                <el-input v-model="adjustDebitAmount" placeholder="请输入调整后的值"></el-input>
            </el-form-item>
        </el-form>
        <span class="dialog-footer">
            <el-button type="primary" @click="changeAmountSubmit">
                提交
            </el-button>
        </span>
    </el-dialog>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import api from '../../../api/api.js';
import dayjs from 'dayjs';
import { useRouter } from 'vue-router';
import { use } from 'echarts/core';
import {
    LineChart
} from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    ToolboxComponent
} from 'echarts/components';
import {
    CanvasRenderer
} from 'echarts/renderers';
use([
    LineChart,
    GridComponent,
    TooltipComponent,
    ToolboxComponent,
    CanvasRenderer
]);
import VChart from 'vue-echarts';
export default {
    props: {
        entityName: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        }
    },
    components: {
        VChart // 注册ECharts组件，以便在模板中使用<e-charts>标签  
    },
    setup(props,context) {
        const adjusts = ref([]);
        // 使用 watch 监听 props.id 的变化  
        watch(() => props.id, (newVal, oldVal) => {
            console.log(`ID changed from ${oldVal} to ${newVal}`);
        });
        const amount = ref(0);
        const debitAmount = ref(0);
        const unDebitAmount = ref(0);
        onMounted(async () => {
            await getRemainAmount();
        })
        //定义显示额度调整框
        const showAdjustWindow = ref(false);
        const adjustAmount = ref(0);
        const adjustDebitAmount = ref(0);
        const changeAmount = () => {
            showAdjustWindow.value = true;
        }
        const getRemainAmount = async () => {
            const cardInfo = await api.GetRemainAmount(props.entityName, props.id);
            adjusts.value = [];
            cardInfo.adjusts.forEach(element => {
                element.createdOn = dayjs(element.createdOn + "Z").format('YYYY-MM-DD HH:mm:ss');
                adjusts.value.push(element);
            });
            amount.value = cardInfo.remainAmount;
            debitAmount.value=cardInfo.debitAmount;
            unDebitAmount.value=cardInfo.unDebitAmount;
        }
        const { emit } = context;  
        const changeAmountSubmit = async () => {
            let tempObj = {};
            tempObj.CardId = props.id;
            tempObj.Amount = adjustAmount.value;
            tempObj.debitAmount = adjustDebitAmount.value;
            await api.CardAdjustSave(tempObj);
            showAdjustWindow.value = false;
            await getRemainAmount();
            emit("updateEvent",true);
        }
        const router = useRouter();
        const showRowInfo = (e) => {
            let tempId = e.id;
            router.push('/pi/amountadjust/' + tempId);
        }
        //画图
        const chart = ref(null);

        const options = ref({
            title: {
                text: '折线图示例'
            },
            tooltip: {},
            xAxis: {
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {},
            series: [{
                name: '销量',
                type: 'line',
                data: [120, 200, 150]
            },
            {
                name: '销量1',
                type: 'line',
                data: [null,null,null,80, 70, 110, 130]
            }]
        });
        return {
            //显式使用setup时，所有的都需要定义
            amount,
            debitAmount,
            unDebitAmount,
            adjusts,
            showAdjustWindow,
            adjustAmount,
            adjustDebitAmount,
            changeAmount,
            changeAmountSubmit,
            showRowInfo,
            chart,
            options
        };
    }
};
</script>