<template>
  <router-view />
</template>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  padding: 0;
  margin: 0;
}

.horizontal-collapse-transition .el-sub-menu__title .el-sub-menu__icon-arrow {
  opacity: 1 !important;
}

.el-dialog__body {
  padding-top: 0px;
}

.el-tabs__content {
  max-height: 450px;
  overflow-y: auto;
}

.demo-tabs>.el-tabs__content {
  padding: 0px 5px !important;
  margin: 10px;
}

.el-tab-pane {
  text-align: left;
}

/* 屏蔽高亮 */
* {
  -webkit-tap-highlight-color: transparent;
}
</style>
<style>
.dou-badge,.dou-badge-nohref {
  font-size: 12px;
  font-weight: normal;
  color: gray;
  margin: 5px 0;
}

.d-tab {
  font-size: 14px;
  color: black;
  font-weight: normal;
}

.d-center {
  display: flex;
  justify-content: center;
}

.d-orange {
  color: #fa8308;
}

.d_span {
  font-size: 12px;
  padding: 2px 5px;
  background-color: #000;
  border-radius: 5px;
  color: white;
  margin-right:3px;
  white-space: nowrap;
}

.d_bd_darkred {
  background-color: darkred !important
}

.d_bd_green {
  background-color: rgb(7, 193, 96) !important
}

.d_bd_orange {
  background-color: #e67e22 !important
}

.d_bd_gray {
  background-color: gray;
}

.d_bd_blue {
  background-color: rgb(75, 95, 154);
}

/*调整tab页高度*/
.el-tabs__content {
  max-height: 550px;
}
</style>
