<template>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">功能</span>
            <div class="d-tab d-center">
                <el-button type="primary" @click="saveSafeKeyHold">新建密钥</el-button>
                <el-button type="primary" @click="saveChildAccount">新建子账号</el-button>
            </div>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24">
            <div class="d-tab" style="margin: 10px 0">
                <span class="dou-badge">密钥列表</span>
                <el-table :data="saveSafeKeyHoldList" stripe style="width: 100%" @row-click="showRowInfo">
                    <el-table-column width="100" prop="name" label="名称" />
                    <el-table-column width="100" prop="username" label="账号" />
                    <el-table-column width="150" prop="password" label="密码" />
                </el-table>
            </div>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">子账号列表</span>
            <el-table :data="accountChild" stripe style="width: 100%" @row-click="showRowInfo1">
                    <el-table-column width="150" prop="name" label="名称" />
                    <el-table-column width="150" prop="description" label="描述" />
                </el-table>
        </el-col>
    </el-row>
    <el-dialog v-model="dialog1" title="保存密钥" width="90%">
        <el-form label-width="68px">
            <el-form-item label="名称">
                <el-input v-model="form.Name" placeholder="请输入，如登录密码"></el-input>
            </el-form-item>
            <el-form-item label="验证方式" prop="SafeKeyType">
                <el-select v-model="form.AccountType" placeholder="请输入或选择">
                    <el-option v-for="item in enumArr['SafeKeyType']" :key="item.value" :label="item.key"
                        :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="账号">
                <el-input v-model="form.Username" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item v-if="form.AccountType=='0'" label="密码">
                <el-input v-model="form.Password" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="form.Memo" type="textarea" />
            </el-form-item>
        </el-form>
        <span class="dialog-footer">
            <el-button type="primary" @click="submitDialog1">
                提交
            </el-button>
        </span>
    </el-dialog>
</template>

<script setup>
import { ref, onMounted,watch,defineProps,defineEmits } from 'vue';
import api from '../../../api/api.js';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { nextTick } from 'vue';  
import { useStore } from 'vuex';
const props = defineProps(['entityName', "id"]);
const enumArr = ref([]);//枚举
const dialog1 = ref(false);//保存密钥弹窗控制
const form=ref({});//密钥表单
const saveSafeKeyHoldList=ref([]);//密钥列表
const accountChild=ref([]);//子账号列表
const emit = defineEmits(['updateEvent']); 
const router = useRouter();
const store = useStore();  
watch(() => props.id, () => {  
    pageInit();
});  
onMounted(() => {
    initEnum(["SafeKeyType"]);
    pageInit();
})
const initEnum = async (enums) => {
    if (enums.length > 0) {
        //查询所需枚举
        const enumList = await api.GetGenericEnum(enums);
        // 处理登录成功的逻辑
        if (enumList.length > 0) {
            enumList.forEach(m => {
                enumArr.value[m.name] = m.data;
            });
        }
    }
}
const saveSafeKeyHold = () => {
    dialog1.value = true;
}
const pageInit = async () => {
    let tempExtData = await api.GetAccountExt(props.id);
    saveSafeKeyHoldList.value = [];
    tempExtData.safeKeyHold.forEach(element => {
        saveSafeKeyHoldList.value.push(element);
    });
    await nextTick();
    accountChild.value = [];
    tempExtData.child.forEach(element => {
        accountChild.value.push(element);
    });
}
const submitDialog1 = async () => {
    let tempObj = form.value;
    tempObj.PId = props.id;
    try {
        await api.SaveSafeKeyHold(tempObj);
        ElMessage({
            message: "操作成功",
            type: 'success'
        })
        dialog1.value = false;
        await pageInit();
        emit("updateEvent", true);
    } catch (e) {
        console.log(e);
        ElMessage({
            message: e.response.data,
            type: 'error'
        })
    }
}
const saveChildAccount=()=>{
    router.push('/ps/account/0?pid=' + props.id);
}
const showRowInfo = (e) => {
    let tempId = e.id;
    router.push('/pi/safekeyhold/' + tempId);
}
const showRowInfo1 = (e) => {
    let tempId = e.id;
    router.push('/pi/account/' + tempId);
    emit("updateEvent", true);
    //切换标签页
    store.commit('generic/SET_CHILDUPDATETAB',true);  
}
</script>
<style>
.demo-tabs>.el-tabs__content{
    font-weight:normal;
}
</style>