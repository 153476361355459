  import axios from 'axios';
  const USER_STORAGE_KEY = 'USER_KEY';
  const TOKEN_STORAGE_KEY = 'TOKEN_KEY';
  export default {
    namespaced: true, // 开启命名空间
    state: {
      user: null,
      token: null,
      loginTime:null,
      logoutCallback: null,
      showSaveComponent:null,//控制通用保存页面显示
      refreshList:null,//控制通用列表刷新通知
      showInfoComponent:null,//控制详情页是否显示
      infoId:null,//控制通用info的id
      douTable:null
    },
    mutations: {
      SET_USER(state, user) {
        state.user = user;
      },
      SET_TOKEN(state, token) {
        state.token = token;
      },
      SET_LOGIN_TIME(state, loginTime) {
        state.loginTime = loginTime;
      },
      SET_LOGOUT_CALLBACK(state, callback) {
        state.logoutCallback = callback;
      },
      //监听通用保存状态
      SET_SAVE_STATUS(state, value) {
        state.showSaveComponent = value;
      },
      SET_REFRESH_LIST(state, value) {
        state.refreshList = value;
      },
      SET_INFO_ID(state,value){
        state.infoId=value;
      },
      SET_INFO_STATUS(state, value) {
        state.showInfoComponent = value;
      },
      SET_DOU_TABLE(state,value){
        state.douTable=value;
      }
    },
    actions: {
      async loginUser({
        commit
      }, {
        username,
        password
      }) {
        console.log(process.env.VUE_APP_API_BASE_URL);
        try {
          // 发送 POST 请求到登录接口
          const response = await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/Auth/authenticate?role=99', {
            username,
            password,
          });

          // 如果登录成功，保存用户信息和 token
          commit('SET_USER', response.data);
          commit('SET_TOKEN', response.data.token);

          // 保存用户信息和 token 到本地存储
          localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(response.data));
          localStorage.setItem(TOKEN_STORAGE_KEY, response.data.token);

          // 保存登录时间到本地存储
          const loginTime = new Date().toISOString();
          localStorage.setItem('loginTime', loginTime);
          commit('SET_LOGIN_TIME', loginTime);

          return true; // 登录成功
        } catch (error) {
          console.error('登录失败', error);
          return error; // 登录失败
        }
      },
      restoreLoginState(context) {
        // 尝试从 localStorage 恢复用户信息和 token
        const storedUser = localStorage.getItem(USER_STORAGE_KEY);
        const storedToken = localStorage.getItem(TOKEN_STORAGE_KEY);
        const storedLoginTime = localStorage.getItem('loginTime');

        if (storedUser && storedToken) {
          const user = JSON.parse(storedUser);
          const token = storedToken;

          // 更新 Vuex 状态
          context.commit('SET_USER', user);
          context.commit('SET_TOKEN', token);
          context.commit('SET_LOGIN_TIME', storedLoginTime);

          // 检查登录时间是否超过七天
          if (storedLoginTime) {
            const loginTime = new Date(storedLoginTime);
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

            if (loginTime < sevenDaysAgo) {
              // 登录时间超过七天，执行注销操作
              context.dispatch('logout');
            }
          }
        }
      },
      logout({
        commit,
        state
      }) {
        // 清除用户信息和 Token
        commit('SET_USER', null);
        commit('SET_TOKEN', null);
        commit('SET_LOGIN_TIME', null);

        // 清除本地存储中的用户信息、Token 和登录时间
        localStorage.removeItem(USER_STORAGE_KEY);
        localStorage.removeItem(TOKEN_STORAGE_KEY);
        localStorage.removeItem('loginTime');

        // 调用回调函数
        if (state.logoutCallback) {
          state.logoutCallback();
        }
      },
      setLogoutCallback({ commit }, callback) {
        commit('SET_LOGOUT_CALLBACK', callback);
      }
    },
    getters: {
      getUser: (state) => {
        console.log('Getting user:', state.user);
        return state.user;
      },
      getUserRole: (state) => {
        if(state.user==null){
          return null;
        }
        return state.user.role;
      },
      isAuthenticated: (state) =>{
        if(state.user==null){
          return false;
        }
        return true;
      },
      getToken: (state) => state.token, // 获取 token
      getLoginTime: (state) => state.loginTime, // 获取 token
      getDouTable:(state)=>state.douTable //获取表
    }
  };