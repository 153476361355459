<template>
    <el-row style="margin: 20px 0;">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">
                主页
            </el-breadcrumb-item>
            <el-breadcrumb-item>{{ entityObj.name }}列表</el-breadcrumb-item>
        </el-breadcrumb>
    </el-row>
    <el-row class="mb-4">
        <el-button @click="openDialogCreate" type="primary">新建</el-button>
    </el-row>
    <el-row style="margin: 20px 0;">
        <GenericListView ref="list" :entityName="entityName"></GenericListView>
    </el-row>
    <el-dialog @closed="closeDialogCreate" v-model="showSaveComponent" :title="'保存' + entityObj.name" width="90%">
        <GenericSave :entityName="entityName"></GenericSave>
    </el-dialog>
    <!-- <el-dialog v-model="dialogInfoVisible" :title="entityObj.name + '详情'" fullscreen="true">
        <LayoutInfo ref="info" :entityName="entityName" :id="id" @douEvent-closeDialogInfo="closeDialogInfo"
            @douEvent-openDialogSave="openDialogSave"></LayoutInfo>
    </el-dialog> -->
</template>
<script>
import { douTable } from '../../../config.js';
import GenericSave from './GenericSave.vue';
import GenericListView from './GenericListView.vue';
import { mapMutations,mapState } from 'vuex';
export default {
    props: ['entityName'],
    data() {
        return {
            entityObj: {}
        }
    },
    // emits:['douEvent-closeDialog','douEvent-closeDialogInfo','douEvent-openDialogInfo'],
    created() {
        console.log("GenericList|" + this.entityName + "|初始化");
        var entity = douTable[this.entityName];
        this.entityObj = entity;
    }
    // ,watch: {
    //     // '$route': function (to) {
    //     //     console.log("Base布局|切换初始化列表");
    //     //     this.entityName = to.query.e;
    //     //     let entity = douTable[to.query.e];
    //     //     this.entityObj = entity;
    //     // }
    // }
    , components: {
        GenericSave,
        GenericListView
    }
    ,computed: {
        ...mapState('user', ['showSaveComponent'])
    }
    , methods: {
        ...mapMutations('user', ['SET_SAVE_STATUS']),
        openDialogCreate() {
            this.id = -1;
            this.SET_SAVE_STATUS(true);
        },
        closeDialogCreate(){
            this.SET_SAVE_STATUS(false);
        }
        // ,closeDialog() {
        //     this.dialogFormVisible = false;
        //     this.id = -1;
        //     this.$refs.list.initDefaultView();
        // },
        // openDialogInfo(id) {
        //     this.id = id;
        //     this.dialogInfoVisible = true;
        // },
        // closeDialogInfo() {
        //     this.dialogInfoVisible = false;
        //     this.$refs.list.initDefaultView();
        // },
        // openDialogSave(id) {
        //     this.dialogInfoVisible = false;
        //     this.id = id;
        //     this.dialogFormVisible = true;
        // }
    }
}
</script>