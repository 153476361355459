<template>
    <div>
        <el-alert title="提示" type="info" description="通用模板列表，根据不同的实体在后台查询不同的数据。" />
    </div>
    <el-table :data="tableData" stripe border style="width: 100%;margin-top: 10px;" @row-click="showRowInfo" empty-text="暂无数据">
        <el-table-column sortable :prop="item.name" :label="item.display" :width="item.width" v-for="item in entityObj.attr"
            v-bind:key="item.name" :align="item.align" />
    </el-table>
    <div class="example-pagination-block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" v-model:current-page="pageNo"
            v-model:page-size="pageSize" layout="sizes, prev, pager, next" :small="small" :total="totalCount"
            :page-size="pageSize" pager-count="5" :page-sizes="[10, 20, 30, 50]" />
    </div>
    <el-dialog  @closed="hideRowInfo" v-model="showInfoComponent" :title="entityObj.name+'详情'" width="90%">
        <LayoutInfo :entityName="entityName" :id="infoId" :canEdit="info.canEdit" :canDelete="info.canDelete"></LayoutInfo>
    </el-dialog>
</template>
<script>
import { douTable } from '../../../config.js'
import api from '../../../api/api.js';
import { mapState,mapMutations } from 'vuex';
import LayoutInfo from '../Layout/LayoutInfo.vue';
export default {
    props: {
        entityName: {
            type: String,
            required: true
        }
        // ,viewName: {
        //     type: String,
        //     required: false
        // }
    },
    data() {
        return {
            entityObj: {},
            tableData: [
            ],
            pageNo: 1,//当前页
            totalPages: 1,//总页数
            pageSize: 10,//页大小
            totalCount: 0,//总记录条数
            small: true,//小分页标签
            infoId:"",//展示的info的id
            info:{
                canEdit:true,
                canDelete:true
            }
        }
    },
    created() {
        var entity = douTable[this.entityName];
        entity.attr.forEach(m=>{
            switch(m.type){
                case "enum":{
                    m.align="center";
                }break;
            }
        });
        this.entityObj = entity;
    },
    mounted() {
        this.GetList();
    },
    components: {
        LayoutInfo
    },
    computed: {
        ...mapState('user', ['showSaveComponent','refreshList','showInfoComponent'])
    },
    watch: {
        showSaveComponent(newFlag) {
            if (newFlag==false){
                this.GetList();
            }
        },
        refreshList(newFlag) {
            if (newFlag==true){
                this.GetList();
                this.hideRowInfo();
            }
        },
    },
    methods: {
        ...mapMutations('user', ['SET_SAVE_STATUS','SET_REFRESH_LIST','SET_INFO_ID','SET_INFO_STATUS']),
        async GetList() {
            try {
                this.infoId=null;
                const response = await api.GetGenericDataList(this.entityName, this.pageNo, this.pageSize);
                // 处理登录成功的逻辑
                response.data.forEach(element => {
                    this.entityObj.attr.forEach(attr=>{
                        if(attr.type=="enum"){
                            element[attr.name]=element[attr.enum+"Name"];
                        }
                        else if(attr.type=="datetime"){
                            if(element[attr.name]!=null && element[attr.name]!=""){
                                element[attr.name]=new Date(element[attr.name]).toLocaleString();
                            }
                        }
                        else if(attr.type=="date"){
                            if(element[attr.name]!=null && element[attr.name]!=""){
                                element[attr.name]=element[attr.name].split('T')[0];
                            }
                        }
                        if(element[attr.name]==null || element[attr.name]==""){
                            element[attr.name]="-";
                        }
                        if(element[attr.name].length>100){
                            element[attr.name]=element[attr.name].substr(0,30)+"...";
                        }
                    });
                });
                this.tableData = response.data;
                this.totalPages = response.totalPages;
                this.pageSize = response.pageSize;
                this.totalCount = response.totalCount;
            } catch (error) {
                // 处理登录失败的逻辑
                console.error('Login failed:', error);
            }
            this.SET_REFRESH_LIST(false);
        },
        handleSizeChange() {
            this.GetList();
        },
        handleCurrentChange() {
            this.GetList();
        },
        initDefaultView() {
            // console.log("初始化列表|" + this.localEntityName);
            // var self = this;
            // var entity = douTable[self.localEntityName];
            // self.entityObj = entity;
            // self.tableData = [];
            // var entityAttr = entity.attr.filter(m => m.visable != false);
            // var tempCols = [];
            // entityAttr.forEach(m => {
            //     var tempCol = {};
            //     tempCol.name = m.name;
            //     tempCol.display = m.display;
            //     tempCol.width = 200;
            //     tempCol.type = m.type;
            //     tempCols.push(tempCol);
            // });
            // self.cols = tempCols;
            // //初始化数据
            // var tempLinkList = [];
            // var tempLinkResult = [];
            // db.table(self.localEntityName).each((m) => {
            //     var tempObj = {};
            //     tempCols.forEach(n => {
            //         if (m[n.name] != null && m[n.name].toString().trim() != "") {
            //             switch (n.type) {
            //                 case 'date': {
            //                     tempObj[n.name] = m[n.name].toLocaleDateString();
            //                 } break;
            //                 case 'time': {
            //                     tempObj[n.name] = m[n.name].toTimeString().split(' ')[0];
            //                 } break;
            //                 case 'textarea': {
            //                     if (m[n.name].length > 20) {
            //                         tempObj[n.name] = m[n.name].substr(0, 20) + "...";
            //                     } else {
            //                         tempObj[n.name] = m[n.name];
            //                     }
            //                 } break;
            //                 case 'selectData': {
            //                     var tempFilter = tempLinkList.filter(j => j.key == n.name);
            //                     if (tempFilter.length == 0) {
            //                         var tempLinkObj = {};
            //                         tempLinkObj.key = n.name;
            //                         tempLinkObj.value = [m[n.name]];
            //                         tempLinkList.push(tempLinkObj);
            //                     } else {
            //                         tempFilter[0].value.push(m[n.name]);
            //                     }
            //                     tempObj[n.name] = m[n.name];
            //                 } break;
            //                 default: {
            //                     tempObj[n.name] = m[n.name];
            //                 }
            //             }
            //         } else {
            //             tempObj[n.name] = "-";
            //         }
            //     });
            //     tempObj.id = m.id;
            //     self.tableData.push(tempObj);
            // }).then(() => {
            //     var taskList = tempLinkList.map(j => {
            //         var tempAttr = entityAttr.filter(k => k.name == j.key)[0];
            //         var linkTableName = tempAttr.link.entity;
            //         return db.table(linkTableName).where(tempAttr.link.key).anyOf(j.value).each(k => {
            //             var tempFilter = tempLinkResult.filter(l => l.key == j.key);
            //             if (tempFilter.length == 0) {
            //                 var tempLinkObj = {};
            //                 tempLinkObj.key = j.key;
            //                 tempLinkObj.value = [{ key: k[tempAttr.link.key], value: k[tempAttr.link.value] }];
            //                 tempLinkResult.push(tempLinkObj);
            //             } else {
            //                 tempLinkResult[0].value.push({ key: k[tempAttr.link.key], value: k[tempAttr.link.value] });
            //             }
            //         });
            //     });

            //     Promise.all(taskList).then(() => {
            //         self.tableData.forEach(m => {
            //             tempLinkResult.forEach(j => {
            //                 var tempValueArr = j.value.filter(k => k.key == m[j.key]);
            //                 if (tempValueArr.length == 0) {
            //                     if (m[j.key] != "-") {
            //                         m[j.key] = "/";
            //                     }
            //                 } else {
            //                     m[j.key] = tempValueArr[0].value;
            //                 }
            //             });
            //         });
            //     });
            // });
        },
        showRowInfo(e){
            this.infoId=e.id;
            this.SET_INFO_STATUS(true);
        },
        hideRowInfo(){
            this.SET_INFO_STATUS(false);
        },
    }
}
</script>
<style scoped>
.example-pagination-block+.example-pagination-block {
    margin-top: 10px;
}

.example-pagination-block .example-demonstration {
    margin-bottom: 16px;
}

.example-pagination-block {
    margin-top: 5px;
}
</style>