<template>
    <BillTypePie :type="type" :startDate="billTypePieForm.start" :endDate="billTypePieForm.end"></BillTypePie>
</template>
<script setup>
import BillTypePie from "./Chart/BillTypePie.vue";
import { ref } from 'vue';
import dayjs from 'dayjs';
import { useRoute } from 'vue-router';
const billTypePieForm=ref({});
billTypePieForm.value.start = dayjs().startOf('day').format('YYYY-MM-DD'); // 当天日期，时间为 0 时
billTypePieForm.value.end = dayjs().add(10, 'day').startOf('day').format('YYYY-MM-DD');

const route = useRoute();
console.log(route);
let typeValue = route.query.type; // 获取 params 中的 "type" 参数
const type=ref(typeValue);
</script>