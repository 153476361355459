<!-- 放置如密钥管理之类的自分组功能 -->
<template>
    <el-row style="margin: 20px 0;">
        <GlobalBreadcrumb :name="entityName" suffixStr="列表"></GlobalBreadcrumb>
    </el-row>
    <SearchList :entityName="entityName" :canAdd="true"></SearchList>
</template>
<script>
import SearchList from '../../Common/SearchList.vue';
export default {
    props: ['entityName']
    ,components: {
        SearchList
    }
}
</script>