<template>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">账户信息</span>
            <el-descriptions class="margin-top" :column="1" border>
                <el-descriptions-item>
                    <template #label>
                        <div class="cell-item">
                            账户金额
                        </div>
                    </template>
                    <span v-html="accountAmount"></span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>
                        <div class="cell-item">
                            已用额度
                        </div>
                    </template>
                    <span v-html="creditAmount"></span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>
                        <div class="cell-item">
                            可用金额
                        </div>
                    </template>
                    <span v-html="availableAmount"></span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>
                        <div class="cell-item">
                            借贷金额
                        </div>
                    </template>
                    <span v-html="borrowAmount"></span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>
                        <div class="cell-item">
                            结余
                        </div>
                    </template>
                    <span v-html="balance"></span>
                </el-descriptions-item>
            </el-descriptions>
        </el-col>
    </el-row>
    <el-row style="margin-top: 20px;">
        <el-col :col="24">
            <span class="dou-badge">月趋势</span>
        </el-col>
    </el-row>
    <!-- <el-row>
        <el-col :col="24">
            <div class="d-tab" style="margin: 10px 0">
                <span class="dou-badge">近三次调额记录</span>
                <el-table :data="adjusts" stripe style="width: 100%" @row-click="showRowInfo">
                    <el-table-column prop="createdOn" label="调整时间" width="155" />
                    <el-table-column prop="amount" label="未入账" />
                    <el-table-column prop="debitAmount" label="已入账" />
                </el-table>
            </div>
        </el-col>
    </el-row> -->
    <!-- <el-row>
        <el-col :col="24" style="height: 300px;">
            <v-chart class="chart" :option="options" autoresize />
        </el-col>
    </el-row>
    <el-dialog v-model="showAdjustWindow" title="调整额度" width="90%">
        <el-form label-width="68px">
            <el-form-item label="未用额度">
                <el-input v-model="adjustAmount" placeholder="请输入调整后的值"></el-input>
            </el-form-item>
            <el-form-item label="已用额度">
                <el-input v-model="adjustDebitAmount" placeholder="请输入调整后的值"></el-input>
            </el-form-item>
        </el-form>
        <span class="dialog-footer">
            <el-button type="primary" @click="changeAmountSubmit">
                提交
            </el-button>
        </span>
    </el-dialog> -->
</template>

<script setup>
import { ref, onMounted } from 'vue';
import api from '../../../api/api.js';
// import dayjs from 'dayjs';
// import { useRouter } from 'vue-router';
import { use } from 'echarts/core';
import {
    LineChart
} from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    ToolboxComponent
} from 'echarts/components';
import {
    CanvasRenderer
} from 'echarts/renderers';
use([
    LineChart,
    GridComponent,
    TooltipComponent,
    ToolboxComponent,
    CanvasRenderer
]);
// import VChart from 'vue-echarts';
const availableAmount = ref(0);
const creditAmount = ref(0);
const accountAmount = ref(0);
const borrowAmount = ref(0);
const balance = ref(0);
onMounted(async () => {
    const baseData = await api.GetBillAllInfo(); // 在 onMounted 钩子中调用异步 API  
    availableAmount.value = baseData.availableAmount;
    creditAmount.value = baseData.creaditAmount; // 注意：这里也可能是 creditAmount  
    accountAmount.value = baseData.accountAmount;
    borrowAmount.value = baseData.borrowAmount;
    balance.value = baseData.balance;
});  
</script>