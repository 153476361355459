<template>
    <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane label="基础" name="first">
            <GenericInfo v-if="entityName != null && id != null" :entityName="entityName" :id="localId"></GenericInfo>
            <el-row justify="center" style="margin-top: 20px;" v-if="canEdit==true || canDelete==true">
                <el-button v-if="canEdit" type="primary" @click="edit">编辑</el-button>
                <el-button v-if="canDelete" type="danger" @click="del">删除</el-button>
            </el-row>
        </el-tab-pane>
        <el-tab-pane label="附加" name="second">
            <component :is="currentComponent" :ext="extData"></component>
        </el-tab-pane>
    </el-tabs>
</template>
<script>
import { douTable } from '../../../config.js'
import api from '../../../api/api.js'
import { ref } from 'vue'
import GenericInfo from '../Generic/GenericInfo.vue'
import { mapMutations } from 'vuex';
import { ElMessageBox } from 'element-plus'
import { ElMessage } from 'element-plus'

export default {
    props: {
        entityName: {
            type: String,
            required: true
        },
        id: {
            type: Number,
            required: true
        }
        ,canEdit: {
            type: Boolean
        },
        canDelete: {
            type: Boolean
        }
    },
    data() {
        return {
            entityObj: {},
            activeName: ref('first'),
            currentComponent: null,
            extData: {},
            localId:null//全局参数可能会影响到info，所以此处不直接修改id值，复制参数
        }
    },
    created() {
        var entity = douTable[self.entityName];
        this.entityObj = entity;
        this.extData.entityName = this.entityName;
        this.extData.id = this.id;
        this.localId=this.id;
        console.log("LayoutInfo的初始化id:" +  this.localId);
    },
    watch: {
        id(newValue,oldvalue) {
            console.log("LayoutInfo的id变更:" + oldvalue + "->" + newValue);
            this.localId=newValue;
        },
    },
    components: {
        GenericInfo
    },
    methods: {
        ...mapMutations('user', ['SET_REFRESH_LIST','SET_SAVE_STATUS','SET_INFO_STATUS','SET_INFO_ID']),
        edit() {
            this.SET_SAVE_STATUS(true);
            this.SET_INFO_STATUS(false);
            this.SET_INFO_ID(this.id);
            this.localId=null;
        },
        del() {
            ElMessageBox.confirm('删除后不可撤销，是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then( async () => {
                var response=await api.DeleteGenericDataById(this.entityName,this.id);
                if(response){
                    this.SET_REFRESH_LIST(true);
                    ElMessage({
                        message: '删除成功！',
                        type: 'success',
                    })
                }else{
                    ElMessage.error('删除失败，请重试！')
                }
            }).catch(() => {

            });
        }
    }
}
</script>
<style>
.demo-tabs>.el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
}

.el-descriptions {
    margin-top: 20px;
}

.cell-item {
    display: flex;
    align-items: center;
}

.margin-top {
    margin-top: 20px;
}
</style>