<template>
    <el-descriptions class="margin-top" :column="1" border>
        <el-descriptions-item v-for="item in attrs" v-bind:key="item">
            <template #label>
                <div class="cell-item" style="min-width: 60px;">
                    {{ item.display }}
                </div>
            </template>
            <span v-html="info[item.name]" :class="{ 'd-link': item.type == 'link' }"
                @click="gotoLink(item.name)"></span>
        </el-descriptions-item>
    </el-descriptions>
</template>
<script>
import { mapState } from 'vuex';
import api from '../../../api/api.js';
import dayjs from 'dayjs';
export default {
    props: {
        entityName: {
            type: String,
            required: true
        }
        , id: {
            type: String,
            required: true
        }, type: {
            type: String,
            default: 'w'
        }, 
        version: {
            type: Number,//用户更新数据
            default: 1
        }
    },
    data() {
        return {
            entityObj: {},
            attrs: [],
            info: {}
        }
    },
    watch: {
        id(newValue,oldValue) {
            console.log("GenericInfo的id变更:" + oldValue + "->" + newValue);
            this.entityObj = this.douTable[this.entityName];
            this.initBase();
        }, version(newValue,oldValue) {
            console.log("我的值"+oldValue+"->"+newValue);
            this.initBase();
        }
    },
    created() {
        this.entityObj = this.douTable[this.entityName];
    },
    mounted() {
        this.initBase();
    },
    computed: {
        ...mapState('user', ['douTable'])
    },
    components: {

    },
    methods: {
        async initBase() {
            var resopnse = await api.GetGenericDataById(this.entityObj.entityName, this.id);
            if (resopnse != null) {
                var data = resopnse;
                this.tableData = [];
                var entityAttr = this.entityObj.attr.filter(m => m.visable != false && m.infoIgnore==null);
                for (var i = 0; i < entityAttr.length; i++) {
                    if (entityAttr[i].biz != null) {
                        var bizList = entityAttr[i].biz.filter(m => m.value == data[entityAttr[i].name]);
                        if (bizList.length > 0) {
                            var bizArr = bizList[0].show;
                            bizArr.forEach(element => {
                                entityAttr.push(this.entityObj.attr.filter(m => m.name == element)[0]);
                            });
                        }
                    }
                }
                //初始化数据
                var tempObj = {};
                entityAttr.forEach(n => {
                    var ccName = n.name;
                    if (data[ccName] != null && data[ccName].toString().trim() != "") {
                        switch (n.type) {
                            case 'date': {
                                tempObj[ccName] = data[ccName].split('T')[0];
                            } break;
                            case 'datetime': {
                                if (data[ccName] != null && data[ccName] != "") {
                                    tempObj[ccName] = dayjs(data[ccName] + "Z").format('YYYY-MM-DD HH:mm:ss');
                                }
                            } break;
                            case "enum": {
                                tempObj[ccName] = data[ccName + "Name"];
                            } break;
                            // case 'selectData': {
                            //     var tempFilter = tempLinkList.filter(j => j.key == ccName);
                            //     if (tempFilter.length == 0) {
                            //         var tempLinkObj = {};
                            //         tempLinkObj.key = ccName;
                            //         tempLinkObj.value = [m[ccName]];
                            //         tempLinkList.push(tempLinkObj);
                            //     } else {
                            //         tempFilter[0].value.push(m[ccName]);
                            //     }
                            //     tempObj[ccName] = m[ccName];
                            // } break;
                            case 'textarea': {
                                tempObj[ccName] = data[ccName].split('\n').join('</br>');
                            } break;
                            case 'link': {
                                tempObj[ccName + "LinkId"] = data[ccName];
                                tempObj[ccName + "LinkEntity"] = data[ccName + "LinkEntity"];
                                tempObj[ccName] = data[ccName + "LinkName"];
                            } break;
                            case 'switch': {
                                tempObj[ccName] = data[ccName]==true?"是":"否";
                            } break;
                            default: {
                                tempObj[ccName] = data[ccName];
                            }
                        }
                    } else {
                        tempObj[ccName] = "-";
                    }
                });
                //删除空余属性
                for(var e=0;e<entityAttr.length;e++){
                    if(tempObj[entityAttr[e].name]=="-"){
                        entityAttr.splice(e,1);
                        e-=1;
                    }
                }
                this.attrs = entityAttr;
                this.info = tempObj;
            }
        },
        gotoLink(name) {
            if (this.info[name + "LinkEntity"] != null) {
                //跳转页面
                let linkEntity = this.info[name + "LinkEntity"].toLocaleLowerCase();
                let linkEntityId = this.info[name + "LinkId"];
                this.$router.push('/pi/' + linkEntity + "/" + linkEntityId);
            }
        }
    }
}
</script>
<style scoped>
.d-link {
    color: #409eff;
}
</style>