<template>
    <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
            <el-select-v2 v-model="searchText" style="width: 240px" filterable remote :remote-method="remoteMethod"
                clearable :options="options" :loading="loading" placeholder="请输入关键词">
                <template #loading>
                    <svg class="circular" viewBox="0 0 50 50">
                        <circle class="path" cx="25" cy="25" r="20" fill="none" />
                    </svg>
                </template>
            </el-select-v2>
        </el-form-item>
        <el-form-item>
            <el-button v-if="canAdd" type="primary" @click="openDialogCreate" :saveHref="saveHref">
                <el-icon>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path>
                    </svg>
                </el-icon>
            </el-button>
        </el-form-item>
    </el-form>
    <el-row v-if="list.length > 0">
        <el-col :col="24">
            <el-collapse accordion="true">
                <el-collapse-item v-for="(item, index) in list" :title="item.name" :name="index + 1"
                    v-bind:key="index + 1">
                    <div style="margin-top: 5px;">
                        <el-button type="primary">
                            <el-icon>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(255,255,255,1)">
                                    <path
                                        d="M16.7574 2.99677L14.7574 4.99677H5V18.9968H19V9.23941L21 7.23941V19.9968C21 20.5491 20.5523 20.9968 20 20.9968H4C3.44772 20.9968 3 20.5491 3 19.9968V3.99677C3 3.44448 3.44772 2.99677 4 2.99677H16.7574ZM20.4853 2.09727L21.8995 3.51149L12.7071 12.7039L11.2954 12.7063L11.2929 11.2897L20.4853 2.09727Z">
                                    </path>
                                </svg>
                            </el-icon>
                            <span> 编辑</span></el-button>
                        <el-button type="primary" @click="goInfo(item.id)">
                            <el-icon>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(255,255,255,1)">
                                    <path
                                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z">
                                    </path>
                                </svg>
                            </el-icon>
                            <span> 详情</span>
                        </el-button>
                        <el-button type="danger">
                            <el-icon>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(255,255,255,1)">
                                    <path
                                        d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM9 11H11V17H9V11ZM13 11H15V17H13V11ZM9 4V6H15V4H9Z">
                                    </path>
                                </svg>
                            </el-icon>
                            <span> 删除</span>
                        </el-button>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </el-col>
    </el-row>
    <el-row v-if="list.length > 0" style="margin-top: 5px;">
        <el-col :col="24">
            <div class="example-pagination-block">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    v-model:current-page="pageNo" v-model:page-size="pageSize" layout="sizes, prev, pager, next"
                    :small="small" :total="totalCount" :page-size="pageSize" :pager-count="5"
                    :page-sizes="[10, 20, 30, 50]" />
            </div>
        </el-col>
    </el-row>
    <el-row v-if="list.length == 0">
        <el-col :col="24">
            <div style="display:flex;justify-content: center;">
                <span style="color: gray;font-size: 14px;">暂无内容</span>
            </div>
        </el-col>
    </el-row>
    <el-row>
        <el-dialog @closed="closeDialogCreate" v-model="showSaveComponent" :title="'保存' + entityObj.name" width="90%">
            <GenericSave :entityName="entityName"></GenericSave>
        </el-dialog>
    </el-row>
</template>
<script>
import api from '../../api/api.js';
import { mapMutations, mapState } from 'vuex';
import GenericSave from '../Content/Generic/GenericSave.vue';
export default {
    props: {
        entityName: {
            type: String,
            required: true
        },
        canAdd: {
            type: Boolean,
            default: false,
        },
        saveHref: {
            type: String
        },
    },
    data() {
        return {
            entityObj: {},//表对象
            list: {},//列表
            searchText: "",//搜索内容
            showAdd: false,//是否显示add按钮
            pageNo: 1,//当前页
            totalPages: 1,//总页数
            pageSize: 10,//页大小
            totalCount: 0,//总记录条数
            small: true,//小分页标签
            options: []
        }
    },
    created() {
        console.log(this.douTable);
        var entity = this.douTable[this.entityName];
        this.entityObj = entity;
    },
    async mounted() {
        this.getDataList();
    },
    computed: {
        ...mapState('user', ['showSaveComponent', 'douTable'])
    },
    watch: {
        showSaveComponent(newVal) {
            if (newVal == false) {
                this.getDataList();
            }
        },entityName(newVal){
            let entity = this.douTable[newVal];
            this.entityObj = entity;
            this.getDataList();
        }
    },
    components: {
        GenericSave
    },
    methods: {
        ...mapMutations('user', ['SET_SAVE_STATUS']),
        async getDataList() {
            const response = await api.GetGenericDataList(this.entityObj.entityName, this.pageNo, this.pageSize);
            this.list = response.data;
            this.list.forEach(m => {
                if (m.name == null) {
                    m.title = m.name;
                }
            });
            this.totalPages = response.totalPages;
            this.pageSize = response.pageSize;
            this.totalCount = response.totalCount;
        },
        openDialogCreate(event) {
            const saveHref = event.currentTarget.getAttribute('saveHref');
            if (saveHref) {
                this.$router.push({ path: saveHref });
            }
            this.showAdd = true;
            this.SET_SAVE_STATUS(true);
        },
        closeDialogCreate() {
            this.showAdd = false;
            this.SET_SAVE_STATUS(false);
        },
        handleSizeChange() {
            this.getDataList();
        },
        handleCurrentChange() {
            this.getDataList();
        },
        goInfo(id) {
            this.$router.push({ path: "/pi/" + this.entityName + "/" + id });
        },
        remoteMethod() {

        },
        loading() {

        }
    }
}
</script>
<style>
.el-form--inline .el-form-item {
    margin-right: 10px;
}
</style>