<template>
    <div class="common-layout">
        <el-row>
            <el-col :span="24">
                <GlobalBreadcrumb :name="entityName"></GlobalBreadcrumb>
            </el-col>
        </el-row>
        <el-row style="margin:10px 0">
            <el-col :span="8">
                <div class="grid-content ep-bg-purple-light d-box">
                    <b>{{ homeInfo.monthInAmount }}</b>
                    <span style="font-size: 14px;">本月收入</span>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content ep-bg-purple d-box">
                    <b>{{ homeInfo.monthOutAmount }}</b>
                    <span style="font-size: 14px;">本月支出</span>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content ep-bg-purple d-box" @click="goUrl('BillAll')">
                    <b :class="amountClass">{{ homeInfo.amount }}</b>
                    <span style="font-size: 14px;">总结余</span>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-button-group class="ml-4">
                <el-button size="small" type="primary">
                    <el-icon>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(255,255,255,1)">
                            <path
                                d="M3.00488 2.99979H21.0049C21.5572 2.99979 22.0049 3.4475 22.0049 3.99979V19.9998C22.0049 20.5521 21.5572 20.9998 21.0049 20.9998H3.00488C2.4526 20.9998 2.00488 20.5521 2.00488 19.9998V3.99979C2.00488 3.4475 2.4526 2.99979 3.00488 2.99979ZM20.0049 10.9998H4.00488V18.9998H20.0049V10.9998ZM20.0049 8.99979V4.99979H4.00488V8.99979H20.0049ZM14.0049 14.9998H18.0049V16.9998H14.0049V14.9998Z">
                            </path>
                        </svg>
                    </el-icon><span style="margin-left: 5px;">账户管理</span>
                </el-button>
                <!-- <el-button size="small" type="primary" :icon="Share" />
                    <el-button size="small" type="primary" :icon="Delete" /> -->
            </el-button-group>
        </el-row>
        <DouList :infoHref="'/pi/bill'" :entityName="'Bill'"
            :listProp="listProp">
            <template #soltAdd>
                <el-button @click="goPath('/Bill/Save/0')">
                    <el-icon>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path>
                        </svg>
                    </el-icon>
                </el-button>
            </template>
        </DouList>
    </div>
</template>
<script>
import DouList from '../../Content/Dou/DouList.vue';
import api from '../../../api/api.js';
export default {
    data() {
        return {
            entityName: "bill",
            homeInfo: {},
            listProp: {}
        }
    },
    created() {
        this.listProp.name = "m.name";
        this.listProp.memo1 = "m.billType";
        this.listProp.rname = "m.inComeType==1?('-'+m.amount.toFixed(2)):('+'+m.amount.toFixed(2))";
        //this.getDataList();
    },
    async mounted() {
        await this.getHomeInfo();
    },
    computed: {
        amountClass() {
            if (this.homeInfo.amount > 0) {
                return 'd-orange';
            }else {
                // 如果 a 等于 0，不返回任何类名，即不添加类  
                return '';
            }
        }
    },
    methods: {
        async getHomeInfo() {
            const response = await api.GetBillHomeInfo();
            this.homeInfo.monthInAmount = response.monthInAmount.toFixed(2);
            this.homeInfo.monthOutAmount = response.monthOutAmount.toFixed(2);
            this.homeInfo.amount = response.amount.toFixed(2);
        },
        async getDataList() {

        },
        goUrl(url) {
            this.$router.push({ name: url });
        },
        goPath(url){
            this.$router.push({ path: url });
        }
    }, components: {
        DouList
    }
}
</script>
<style>
.d-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.d-green {
    color: green;
}

.d-red {
    color: darkred;
}
</style>