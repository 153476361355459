<template>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">功能列表</span>
            <div class="d-tab d-center">
                <el-button type="primary" @click="saveExec">新增执行</el-button>
                <el-button type="primary" @click="updateStatus">调整状态</el-button>
            </div>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">执行列表</span>
            <el-table :data="execList" :border="true" @row-click="goExec">
                <el-table-column label="#" width="40">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <span>{{ scope.row.index }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="日期" width="98">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <span>{{ scope.row.date }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="状态" width="80">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <el-tag type="primary" v-if="scope.row.status == 0">{{ scope.row.statusName }}</el-tag>
                            <el-tag type="success" v-else-if="scope.row.status == 1">{{ scope.row.statusName }}</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.status == 2">{{ scope.row.statusName }}</el-tag>
                            <el-tag type="info" v-else>{{ scope.row.statusName }}</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="内容" width="200">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <span>{{ scope.row.content }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="预计时刻" width="160">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <span>{{ scope.row.starttime }} - {{ scope.row.endtime }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">近一月趋势</span>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24" style="height: 300px;">
            <v-chart class="chart" :option="option1" autoresize />
        </el-col>
    </el-row>
    <el-dialog v-model="showSaveExec" title="添加执行" width="90%">
        <el-form label-width="68px">
            <el-form-item label="执行日期">
                <el-date-picker v-model="formExec.Date" type="date" placeholder="请选择执行日期" :editable="false"
                    style="width: 100%" :clearable="false" />
            </el-form-item>
            <el-form-item label="开始时刻">
                <el-time-picker v-model="formExec.StartTime" type="time" placeholder="请选择执行日期" :editable="false"
                    style="width: 100%" :clearable="false" :format="'HH:mm'" />
            </el-form-item>
            <el-form-item label="结束时刻">
                <el-time-picker v-model="formExec.EndTime" type="time" placeholder="请选择执行日期" :editable="false"
                    style="width: 100%" :clearable="false" :format="'HH:mm'" />
            </el-form-item>
            <el-form-item label="内容">
                <el-input rows="3" v-model="formExec.Content" type="textarea" />
            </el-form-item>
        </el-form>
        <span class="dialog-footer">
            <el-button type="primary" @click="saveExecSubmit">
                提交
            </el-button>
        </span>
    </el-dialog>
    <el-dialog v-model="showSaveStatus" title="更改目标状态" width="90%">
        <el-form :model="form" label-width="68px" ref="formRef">
            <el-form-item label="状态">
                <el-select v-model="formStatus.status" placeholder="请选择">
                    <el-option v-for="item in selectStatus" :key="item.value" :label="item.key" :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form label-width="68px">
                <el-form-item label="备注">
                    <el-input rows="3" v-model="formStatus.memo" type="textarea" />
                </el-form-item>
            </el-form>
        </el-form>
        <span class="dialog-footer">
            <el-button type="primary" @click="submitStatus">
                提交
            </el-button>
        </span>
    </el-dialog>
</template>
<script setup>
import { ref, defineProps, onMounted, watch, defineEmits } from 'vue';
import { ElMessage } from 'element-plus';
const props = defineProps(['entityName', "id"]);
import { useRouter } from 'vue-router';
import api from '../../../api/api.js';
import { useStore } from 'vuex';
const showSaveExec = ref(false);
const formExec = ref({});
const router = useRouter();
const emit = defineEmits(['updateEvent']);
const execList = ref([]);
const option1 = ref();
const selectStatus = ref([]);
const store = useStore();
import { use } from 'echarts/core';
import {
    LineChart,
    BarChart
} from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    LegendComponent,
    MarkLineComponent
} from 'echarts/components';
import {
    CanvasRenderer
} from 'echarts/renderers';
import { UniversalTransition } from 'echarts/features';
use([
    LineChart,
    BarChart,
    GridComponent,
    TooltipComponent,
    LegendComponent,
    CanvasRenderer,
    UniversalTransition,
    MarkLineComponent
]);
import VChart from 'vue-echarts';

onMounted(() => {
    pageInit();
    //初始化Enum
    initEnum(["Status"]);
})
watch(() => props.id, () => {
    pageInit();
});
const pageInit = async () => {
    let tempExtData = await api.GetGoalExt(props.id);
    execList.value = tempExtData.exec;
    option1.value = tempExtData.option1;
    formStatus.value=tempExtData.info;
}
const initEnum = async (enums) => {
    if (enums.length > 0) {
        //查询所需枚举
        const enumList = await api.GetGenericEnum(enums);
        // 处理登录成功的逻辑
        if (enumList.length > 0) {
            enumList.forEach(m => {
                selectStatus.value = m.data;
            });
        }
    }
}
const saveExec = () => {
    showSaveExec.value = true;
}
const getTimeStr = (time) => {
    var timeStr = time.toString();
    if (time < 10) {
        timeStr = "0" + timeStr;
    }
    return timeStr;
}
const saveExecSubmit = async () => {
    let tempObj = {};
    tempObj.GoalId = props.id;
    tempObj.StartTime = getTimeStr(formExec.value.StartTime.getHours()) + ":" + getTimeStr(formExec.value.StartTime.getMinutes()) + ":00";
    tempObj.EndTime = getTimeStr(formExec.value.EndTime.getHours()) + ":" + getTimeStr(formExec.value.EndTime.getMinutes()) + ":00";
    tempObj.Content = formExec.value.Content;
    tempObj.Date = formExec.value.Date.toLocaleDateString();
    try {
        await api.SaveGoalExec(tempObj);
        ElMessage({
            message: "操作成功",
            type: 'success'
        })
        showSaveExec.value = false;
        pageInit();
        emit("updateEvent", true);
    } catch (e) {
        console.log(e);
        ElMessage({
            message: e.response.data,
            type: 'error'
        })
    }
}
const goExec = (obj) => {
    router.push('/pi/goalexecution/' + obj.id);
}

//保存状态
const showSaveStatus=ref(false);
const formStatus=ref({});
const updateStatus=()=>{
    showSaveStatus.value=true;
}
const submitStatus=async()=>{
    let tempObj = {};
    tempObj.id = props.id;
    tempObj.status = formStatus.value.status;
    tempObj.memo = formStatus.value.memo;
    try {
        await api.UpdateGoalStatus(tempObj);
        ElMessage({
            message: "操作成功",
            type: 'success'
        })
        showSaveStatus.value = false;
        pageInit();
        emit("updateEvent", true);
        //切换标签页
        store.commit('generic/SET_CHILDUPDATETAB', true);
    } catch (e) {
        console.log(e);
        ElMessage({
            message: e.response.data,
            type: 'error'
        })
    }
}
</script>