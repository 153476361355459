//023-12-10之后不再使用dexie.js保存本地数据，数据结构修改为与线上数据库一致
export const dexieVersion = 1.9;
export const douTable = {
    user: {
        name: "用户",
        attr: [{
            name: "id",
            visable: false
        },
        {
            name: "username",
            display: '账号',
            type: "input"
        },
        {
            name: "password",
            display: '密码',
            type: "input"
        },
        {
            name: "nickname",
            display: '昵称',
            type: "input"
        },
        {
            name: "createdOn",
            display: '创建时间',
            visable: false
        }
        ]
    },
    thing: {
        name: "事件",
        attr: [{
            name: "name",
            display: '名称',
            type: "input",
            required: true
        },
        {
            name: "planId",
            display: '计划',
            type: "selectData",
            link: {
                entity: "plan",
                key: "id",
                value: "name"
            }
        },
        {
            name: "sd",
            display: '开始日期',
            type: "date"
        },
        {
            name: "dd",
            display: '结束日期',
            type: "date"
        },
        {
            name: "memo",
            display: '备注',
            type: "textarea"
        },
        {
            name: "userId",
            visable: false,
            value: '000000-000000-000000-000000'
        }
        ]
    },
    Todo: {
        name: "待做",
        attr: [{
            name: "name",
            display: '名称',
            type: "input",
            width: 200,
            required: true
        },
        {
            name: "planId",
            display: '计划',
            type: "selectData",
            link: {
                entity: "thing",
                key: "id",
                value: "name"
            }
        },
        {
            name: "todoType",
            display: '类型',
            type: "enum",
            enum: "TodoType",
            default: 1,
            required: true,
            biz: [
                {
                    value: "2",
                    show: ["startDate", "startTime", "endDate", "endTime"]
                },
                {
                    value: "3",
                    show: ["startDate", "startTime", "endDate", "endTime", "repeatType", "repeatNum"]
                },
                {
                    value: "4",
                    show: ["startDate", "startTime", "endDate", "endTime", "limitType", "limitNum"]
                }
            ]
        },
        {
            name: "startDate",
            display: '开始日期',
            type: "date",
            width: 200,
            required: true,
            visable: false,
            default: new Date()
        },
        {
            name: "endDate",
            display: '结束日期',
            type: "date",
            width: 200,
            required: true,
            visable: false,
            default: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        },
        {
            name: "startTime",
            display: '开始时间',
            type: "time",
            width: 200,
            required: true,
            visable: false,
            default: new Date('0')
        },
        {
            name: "endTime",
            display: '结束时间',
            type: "time",
            width: 200,
            required: true,
            visable: false,
            default: new Date('0')
        },
        {
            name: "repeatType",
            display: '重复类型',
            type: "enum",
            width: 105,
            enum: "RepeatType",
            required: true,
            visable: false
        },
        {
            name: "repeatNum",
            display: '周期间隔',
            type: "number",
            width: 105,
            required: true,
            visable: false
        },
        {
            name: "limitType",
            display: '限制类型',
            type: "enum",
            width: 105,
            enum: "LimitType",
            required: true,
            visable: false
        },
        {
            name: "limitNum",
            display: '限制次数',
            type: "number",
            width: 105,
            required: true,
            visable: false
        },
        {
            name: "memo",
            display: '备注',
            type: "textarea",
            width: 200
        }
        ]
    },
    SafeKeyHold: {
        name: "密钥",
        attr: [{
            name: "title",
            display: '名称',
            type: "input",
            width: 200,
            required: true,
            placeholder: "平台名，例如网易"
        },
        {
            name: "smallTitle",
            display: '子名称',
            type: "input",
            width: 200,
            required: true,
            placeholder: "应用名，例如梦幻"
        },
        {
            name: "safeKeyType",
            display: '密钥类型',
            type: "enum",
            enum: "SafeKeyType",
            default: 1,
            required: true,
            biz: [
                {
                    value: "1",
                    show: ["accountStr"],
                    groupRequired: [{
                        name: "passwordStr",
                        min: 1
                    }]
                }
            ]
        },
        {
            name: "accountStr",
            display: '账号',
            type: "input",
            width: 200,
            required: true,
            visable: false
        },
        {
            name: "passwordStr",
            display: '密码',
            type: "group",
            items: [
                {
                    name: "title",
                    display: "密码描述"
                },
                {
                    name: "value",
                    display: "密码"
                }
            ]
        },
        {
            name: "bindPhone",
            display: '手机号码',
            type: "input",
            width: 200
        },
        {
            name: "bindEmail",
            display: '邮箱',
            type: "input",
            width: 200
        },
        {
            name: "questionStr",
            display: '密保问题',
            type: "input",
            width: 200
        },
        {
            name: "tagStr",
            display: '标签',
            type: "input",
            width: 200
        },
        {
            name: "sourceName",
            display: '来源名称',
            type: "input",
            width: 200
        },
        {
            name: "sourceUrl",
            display: '来源地址',
            type: "input",
            width: 200
        },
        {
            name: "memo",
            display: '备注',
            type: "textarea",
            width: 200
        }
        ]
    },
    scheduleHistory: {
        name: "日程历史",
        attr: [{
            name: "type",
            display: '类型',
            type: "input",
            required: true
        },
        {
            name: "typeId",
            display: '类型id',
            type: "input",
            required: true
        },
        {
            name: "createdBy",
            display: '创建人',
            visable: false,
            value: '000000-000000-000000-000000'
        },
        {
            name: "createdOn",
            display: '创建时间'
        },
        {
            name: "memo",
            display: '备注'
        },
        {
            name: "status",
            display: '完成状态'
        }
        ]
    },
    note: {
        name: "笔记",
        attr: [{
            name: "name",
            display: '名称',
            type: "input",
            required: true
        },
        {
            name: "memo",
            display: '备注',
            type: "textarea"
        },
        {
            name: "userId",
            visable: false,
            value: '000000-000000-000000-000000'
        }
        ]
    }
}