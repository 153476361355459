//通用组件状态保存
export default {
  namespaced: true, // 开启命名空间
  state: {
    //当同一实体穿透时，保证父级tab切换
    childUpdateTab:false
  },
  mutations: {
    SET_CHILDUPDATETAB(state, update) {
      state.childUpdateTab = update;
    }
  },
  actions: {

  },
  getters: {
    
  }
};